import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="teacher--courses"
export default class extends Controller {
  connect() {
    $('.infos').on('click', function () {
      const $currentBox = $(this).find('.info-box')
      $('.info-box').not($currentBox).hide()
      $currentBox.toggle()
    })

    $(document).on('click', function (event) {
      if (!$(event.target).closest('.infos').find('.info-box').length) {
        $('.info-box').hide()
      }
    })

    $('#past-courses').DataTable({
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      buttons: [],
      pageLength: 25,
      responsive: false,
      order: [[1, 'desc']],
    })
  }
}
