import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="admin--reports"
export default class extends Controller {
  connect() {
    let tables = []
    const settings = {
      dom: 'Bfrtip',
      buttons: ['csv', 'excel', 'pageLength'],
      scrollCollapse: true,
      responsive: false,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      pageLength: -1,
      columnDefs: [
        {
          targets: '_all',
          createdCell: function (td, cellData, rowData, row, col) {
            td.setAttribute('data-label', this[0].querySelectorAll('th')[col].innerText)
          },
        },
      ],
    }

    const countPlusInfo = ({ count, data }) => loadBox(count, data)

    const overviewColumns = [
      { data: 'name' },
      { data: 'groups' },
      { data: 'teachers' },
      {
        data: 'students',
        render: (data, _type, row, _meta) => `${data.count} (${row.licenses.new_student})`,
      },
      {
        data: 'licenses',
        render: ({ count, new_student }) => Math.max(count - new_student, 0),
      },
      { data: 'ebooks', render: countPlusInfo },
      { data: 'teoriakoe' },
      { data: 'supervisions', render: countPlusInfo },
      { data: 'online_lessons', render: countPlusInfo },
      { data: 'web_services' },
      { data: 'email' },
    ]

    const urlSettings = {
      ajax: {
        url: '/reports/school_overviews',
        type: 'GET',
        dataType: 'json',
        data: (d) => {
          d.start_time = document.getElementById('start-date').value
          d.end_time = document.getElementById('end-date').value
        },
        dataSrc: (data) => data,
      },
    }

    settings.columns = overviewColumns
    tables.push($('#school-overviews').DataTable({ ...settings, ...urlSettings }))
    settings.columns = null
    settings.columnDefs.push({
      targets: '_all',
      data: function (row, type, val, meta) {
        try {
          return row[meta.settings.aoColumns[meta.col].nTh.dataset.col]
        } catch (e) {
          return ''
        }
      },
    })
    urlSettings.ajax.url = '/reports/student_count'
    tables.push($('#student-count').DataTable({ ...settings, ...urlSettings }))

    document.getElementById('reload-btn').onclick = (event) => {
      tables.forEach((t) => t.ajax.reload())
    }

    function loadBox(count, info) {
      if (info.length < 1) return count

      return `
        <div class="flex gap-2 items-center">
          <div>${count}</div>
          <div class="infos-shared" x-data="{ showInfo: false }" @mousedown.outside="showInfo = false">
            <span class="material-symbols-outlined fill-icon text-red cursor-pointer" @click="showInfo = !showInfo">add_circle</span>
            <div class="info-box-shared" x-show="showInfo" x-cloak>
              ${info.map((r) => `<div>${r.title} (${r.count})</div>`).join(' ')}
            </div>
          </div>
        </div>
      `
    }
  }
}
