// Detecting the browser in use. Returns browser name.
const detectBrowser = () => {
  //Returns True or False.
  // Opera 8.0+
  var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined'

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]'
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia

  // Chrome 1 - 71
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS

  var browsers = undefined

  var browser = undefined

  setBrowser()
  function setBrowser() {
    browsers = [
      { browser: 'opera', isUsed: isOpera },
      { browser: 'firefox', isUsed: isFirefox },
      { browser: 'safari', isUsed: isSafari },
      { browser: 'ie', isUsed: isIE },
      { browser: 'edge', isUsed: isEdge },
      { browser: 'chrome', isUsed: isChrome },
      { browser: 'blink', isUsed: isBlink },
    ]

    for (var i = 0; i < browsers.length; i++) {
      if (browsers[i].isUsed === true) {
        if (browser === 'chrome' || browser === 'opera') {
          browser = browser + ', ' + browsers[i].browser
        } else {
          browser = browsers[i].browser
        }
      }
    }
  }

  //Mobile browser detection - start
  if (browser === undefined) {
    isChrome = !!window.chrome
    if (!!window.chrome === true && !!window.chrome.runtime === false) {
      isChrome = !!window.chrome
    }
    setBrowser()
  }
  //Mobile browser detection - end
  return browser
}

const isWebkit = () => {
  var isWebKit = /WebKit/.test(navigator.userAgent)
  return isWebKit
}

/*
esim:
  var browser_in_use = detectBrowser(); //Find out the browser in use.
  if(browser_in_use===undefined){browser_in_use="firefox";}

  //Adjust styles when using Chrome
  if(browser_in_use==="chrome" || browser_in_use === "chrome, blink"){
      $('.log-out-position').addClass("log-out-position-chrome");
  }
 */

export { detectBrowser, isWebkit }
