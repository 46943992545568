import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="selected-rows"
export default class extends Controller {
  static targets = ['rowCheckbox']

  // Reusable for any type of action (delete multiple, archive multiple etc.)
  // The destructured data-attributes are all required.
  actionWithConfirmation({ target }) {
    const { url, title, text } = target.dataset
    const checked = this.rowCheckboxTargets.filter((box) => box.checked)
    const ids = checked.map((box) => +box.value)

    warningModal(title, text, async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids }),
        })

        // Reload the page after a successful request (DataTables for example gets messed up when deleting rows dynamically)
        response.ok ? location.reload() : alert(`${I18n.saving_failed}\n${response.status} ${response.statusText}`)
      } catch (error) {
        console.error(error)
      }
    })
  }
}
