import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="teacher--student-groups"
export default class extends Controller {
  connect() {
    const pastCoursesTable = $('#group-table').DataTable({
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      buttons: [],
      pageLength: 25,
      responsive: false,
      order: [[0, 'desc']],
      drawCallback: handleClicks,
    })

    function handleClicks() {
      $('.infos')
        .off('click')
        .on('click', function () {
          $(this).find('.info-box').toggle()
          $('.info-box').not($(this).find('.info-box')).hide()
        })

      $(document)
        .off('click')
        .on('click', ({ target }) => {
          if (!$(target).closest('.infos').find('.info-box').length) {
            if ($('.info-box').is(':visible')) {
              $('.info-box').hide()
            }
          }
        })
    }

    $('#delete-groups').on('click', function () {
      warningModal(this.dataset.title, this.dataset.text, () => {
        $('.group-checkbox:checked').each(function () {
          var id = $(this).attr('id').replace('group_', '')
          $.ajax({
            url: '/teacher/student_groups/' + id,
            type: 'DELETE',
            success: () => {
              pastCoursesTable
                .row($('#group_row_' + id + ', .group_class_' + id))
                .remove()
                .draw()
            },
            error: () => alert('failed'),
          })
        })
      })
    })

    handleClicks()
  }
}
