import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="sidebar"
export default class extends Controller {
  connect() {
    $('.mobile-sub-nav-toggle').on('click', function (e) {
      $(this).prev('.mobile-sub-nav').slideToggle('fast')
      $(this).toggleClass('sub-nav-open')
    })

    //MOBILE MENU
    const $toggleButton = $('.toggle-button')
    const $menuWrap = $('#sidebar-content')

    $toggleButton.on('click', function () {
      $(this).toggleClass('button-open')
      $menuWrap.toggleClass('menu-show')

      var controller = $('#sidebar-content').attr('data-current-controller')
      if (controller === 'students' || controller === 'student_groups') {
        var $mobileSubNav = $('#mobile_students_section').find('.mobile-sub-nav-toggle')
        $mobileSubNav.trigger('click')
      } else if (controller === 'courses') {
        var $mobileSubNav = $('#mobile_courses_section').find('.mobile-sub-nav-toggle')
        $mobileSubNav.trigger('click')
      } else if (controller === 'products' || controller === 'product_packages') {
        var $mobileSubNav = $('#mobile_products_section').find('.mobile-sub-nav-toggle')
        $mobileSubNav.trigger('click')
      }
    })

    if (document.querySelector('#teoriakoeharjoittelu-not-activated')) {
      $('#teoriakoeharjoittelu-not-activated').on('mouseover', function () {
        var $notActivated = $(this)
        var $contactSchool = $('#contact-school-text')
        $notActivated.hide()
        $contactSchool.show()

        setTimeout(function () {
          $notActivated.show()
          $contactSchool.hide()
        }, 4000)
      })
    }
  }
}
