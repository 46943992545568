import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="teacher--students--index"
export default class extends Controller {
  connect() {
    $('#delete_student').on('click', ({ target }) => {
      const { title, text } = target.dataset
      archiveStudents('removed', title, text)
    })

    $('#archive_student').on('click', ({ target }) => {
      const { title, text } = target.dataset
      archiveStudents('archived', title, text)
    })

    $('#restore_student').on('click', ({ target }) => {
      const { title, text } = target.dataset
      restoreStudents(title, text)
    })

    function archiveStudents(archiveType, title, text) {
      warningModal(title, text, () => {
        document
          .querySelectorAll(`#${archiveType === 'archived' ? 'active' : 'archived'}-students .student-checkbox:checked`)
          .forEach((checkbox) => {
            const id = checkbox.id.split('student_')[1]

            $.ajax({
              url: `/teacher/students/${id}/archive`,
              type: 'POST',
              dataType: 'html',
              data: { archive_type: archiveType },
              success: (result) => {
                if (archiveType !== 'removed') {
                  $('#archived-students tbody').append(result)
                }
                checkbox.closest('tr').remove()
              },
              error: () => alert(`Failed to ${archiveType === 'archived' ? 'archive' : 'remove'} the student`),
            })
          })
      })
    }

    function restoreStudents(title, text) {
      warningModal(title, text, () => {
        document.querySelectorAll('#archived-students .student-checkbox:checked').forEach((checkbox) => {
          const id = checkbox.id.split('student_')[1]

          $.ajax({
            url: `/teacher/students/${id}/unarchive`,
            type: 'POST',
            dataType: 'html',
            success: (result) => {
              $('#active-students tbody').append(result)
              checkbox.closest('tr').remove()
            },
            error: () => alert('Failed to restore the student'),
          })
        })
      })
    }
  }
}
