import { Controller } from "@hotwired/stimulus"

// Rows with checkboxes that can be selected by clicking anywhere on the row
export default class extends Controller {
  connect() {
    const row = this.element
    const checkbox = row.querySelector('input[type="checkbox"]')

    // Initialize checked rows as `selected` (doesn't need to be set in the HTML)
    if (checkbox.checked) row.classList.add('selected')

    // Toggle checkbox state when clicking on the row (excluding inputs and buttons, which should be clickable without changing the selection)
    row.addEventListener('mousedown', (e) => {
      const { nodeName } = e.target
      if (nodeName === 'INPUT' || nodeName === 'BUTTON') return

      checkbox.checked = !checkbox.checked
      checkbox.dispatchEvent(new Event('change'))
    })

    // Toggle the row's `selected` class when the checkbox state changes
    checkbox.addEventListener('change', () => row.classList.toggle('selected'))
  }
}
