import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // Tawk.to chat widget code
    window.Tawk_API = window.Tawk_API || {}
    const Tawk_LoadStart = new Date()

    // Send user data to the chat API if logged in
    window.Tawk_API.onLoad = () => {
      const path = window.location.pathname
      if (!path.match(/student|teacher/)) return

      const course_id = path.match('live_course') ? path.split('live_course/')[1] : null
      $.get('/chat/get_user_data', { course_id: course_id }).done(({ name, email, hash }) => {
        window.Tawk_API.setAttributes({ name, email, hash }, (error) => error && console.error(error))
      })
    }
  }
}
