import { Controller } from '@hotwired/stimulus'
import Player from '@vimeo/player'

// Connects to data-controller="shared--course-video"
export default class extends Controller {
  connect() {
    //Variables for watching and selecting the videos
    var videoState = {}
    var clicked_video_id = 0
    var play_time = 0
    var start_video = false
    var firstVisit = true
    var openCallback = null
    var previousSave = null

    //Initialize vimeo player with video id
    var selected_course_video_progress = Math.max(
      parseInt($('#selected_course_video_progress_header').attr('data-seconds')) - 1,
      0
    )
    var selected_video_id = parseInt($('#course_video').data('id'))
    var player = new Player('course_video', { id: selected_video_id, portrait: false, byline: false, controls: true })
    var timeWatched = selected_course_video_progress

    //Handler for when selecting video from the list
    $('.video_button').on('click', function () {
      clicked_video_id = parseInt($(this).data('id'))

      if (selected_video_id != clicked_video_id) {
        $('#course_video').data('id', clicked_video_id)
        play_time = parseInt($('#list_course_video_progress_header_' + clicked_video_id).attr('data-seconds'))
        $('#selected_course_video_title').text($('#list_course_video_name_' + clicked_video_id).text())
        $('#selected_course_video_duration').text($('#list_course_video_time_' + clicked_video_id).text())
        $('.selected_course_video_progress').val($('#list_course_video_progress_' + clicked_video_id).val())
        $('#selected_course_video_progress_header').text(
          $('#list_course_video_progress_header_' + clicked_video_id).text()
        )
        start_video = true
        loadVideo(player, clicked_video_id, play_time, start_video)
        timeWatched = play_time
        selected_video_id = clicked_video_id
      }

      //Scrolling back to main video container when video selected from the list
      $('html, body').animate(
        {
          scrollTop: $('.main-content').offset().top,
        },
        600
      )
    })

    // Update video state, data-attributes and header texts when watching a video
    player.on('timeupdate', function (data) {
      // Update current video's watch time
      const seconds = data.seconds
      videoState.watch_time = Math.round(seconds)

      // Update data-attributes and visible text
      updateDatafields(data, openCallback)

      if (seconds - 1 < timeWatched && seconds > timeWatched) {
        timeWatched = seconds
      }

      // Save progress every 30 seconds
      // 'timeupdate' fires 4-5x per second, so keep track of the previous save to prevent multiple saves during the same second
      const watchedSeconds = Math.floor(timeWatched)
      if (watchedSeconds % 30 === 0 && watchedSeconds !== previousSave) {
        previousSave = watchedSeconds
        saveState()
      }
    })

    //Saving users video status when video is paused
    //Working but causes few extra saves because the video pause status
    player.on('pause', () => {
      setTimeout(() => {
        player
          .getPaused()
          .then((paused) => {
            if (paused && !firstVisit) saveState()
          })
          .catch((error) => console.error(error.name))
      }, 1000)
    })

    // Update firstVisit and videoState when starting (or resuming) playback
    player.on('play', ({ seconds, duration }) => {
      if (firstVisit) firstVisit = false
      videoState = {
        video_id: selected_video_id,
        watch_time: Math.round(seconds),
        video_duration: Math.floor(duration),
      }
    })

    //Saving users video status when video is finished
    player.on('ended', saveState)

    //Loading initial video
    loadVideo(player, selected_video_id, selected_course_video_progress, start_video)

    if (window.location.pathname.includes('/live_course/')) {
      // Disable seeking on the video if the user hasn't fully watched it yet
      player.on('seeked', ({ seconds }) => {
        if (timeWatched < seconds) {
          player.setCurrentTime(timeWatched)
        }
      })

      // Trigger an 'openTest' event when all videos have been watched at least 98%, which will unblock the test
      openCallback = function () {
        var incompleteVideos = $('.video-progress').filter(function () {
          return this.value < 98
        })
        if (incompleteVideos.length === 0) $.event.trigger('openTest')
      }

      // This gets loaded before the event listener for 'openTest' is created, so a small timeout is needed
      setTimeout(() => openCallback(), 300)

      window.addEventListener('pauseCourseVideo', (e) => {
        player.getPaused().then((paused) => {
          if (!paused) player.pause()
        })
      })
    }

    //Function for loading specific video from vimeo
    function loadVideo(player, clicked_video_id, play_time, start_video) {
      player
        .loadVideo(clicked_video_id)
        .then((id) => {
          // Make sure play_time is within an acceptable range, confirming the video duration from the Vimeo API first (it might have changed)
          player.getDuration().then((duration) => {
            play_time = Math.min(Math.max(play_time, 1), duration)

            setTimeout(() => {
              player
                .setCurrentTime(play_time)
                .then((seconds) => {
                  if (start_video) player.play()
                })
                .catch((error) => {
                  if (error.name === 'RangeError') alert(error.name)
                })
            }, 1000)
          })
        })
        .catch((error) => {
          switch (error.name) {
            case 'TypeError':
              alert('Type error!')
              break
            case 'PasswordError':
              alert('Wrong password!')
              break
            case 'PrivacyError':
              alert('Private video.')
              break
            default:
              break
          }
        })
    }

    // Save video progress
    async function saveState() {
      const resultData = {
        video_progress: {
          subject_id: $('#course_video').attr('data-value'),
          video_id: videoState.video_id,
          watch_time: videoState.watch_time,
        },
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      const headers = { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' }
      const res = await fetch('/save_vimeo_state', { method: 'POST', headers, body: JSON.stringify(resultData) })

      if (!res.ok) {
        const msg = await res.text()
        flash('alert', msg)
      }
    }

    //Function for updating the data fields
    function updateDatafields(data, openCallback) {
      var percentWatched = data.percent * 100
      var percentageText = Math.round(percentWatched) + '%'
      var id = $('#course_video').data('id')
      $('.selected_course_video_progress').attr('value', percentWatched)
      $('#selected_course_video_progress_header').text(percentageText)
      $('#list_course_video_progress_' + id).attr('value', percentWatched)
      $('#list_course_video_progress_header_' + id).attr('data-seconds', Math.round(data.seconds))
      $('#list_course_video_progress_header_' + id).text(percentageText)

      if (percentWatched >= 100 && openCallback) openCallback()
    }
  }
}
