import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="admin--base"
export default class extends Controller {
  connect() {
    // Add nested attribute fields from a template element
    // NOTE: The button must have a data-target attribute that matches the template's data-content attribute and the target wrapper's id!
    document.querySelectorAll('.add-fields').forEach((addBtn) => {
      addBtn.addEventListener('click', (e) => {
        e.preventDefault()
        const targetId = addBtn.dataset.target
        if (!targetId) return

        const targetWrapper = document.querySelector('#' + targetId)
        const template = document.querySelector('template[data-content="' + targetId + '"]')
        const fields = template.innerHTML.replace(/NESTED_CHILD_INDEX/g, Date.now())
        targetWrapper.insertAdjacentHTML('beforeend', fields)
      })
    })

    // Remove (new fields) or hide nested attribute fields (persisted fields that need to submit a "_destroy" attribute)
    document.querySelectorAll('.delete-fields').forEach((deleteBtn) => {
      deleteBtn.addEventListener('click', () => {
        const fieldset = deleteBtn.closest('fieldset')
        deleteBtn.dataset.persisted === 'false' ? fieldset.remove() : (fieldset.hidden = true)
      })
    })
  }
}
