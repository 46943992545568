import { Controller } from '@hotwired/stimulus'
import { detectBrowser, isWebkit } from '../../utils/browser_detect'

// Connects to data-controller="home--new-main-page"
export default class extends Controller {
  connect() {
    'use strict'
    $(function () {
      $('body').css({ 'background-color': 'rgba(33, 37, 40, 1.0)' })

      var mb_init_vertical_done = false
      var mb_init_horizontal_done = false
      var mb_pref_vertical_done = false
      var mb_pref_horizontal_done = false

      var mb_window_height_vrt = window.innerHeight
      var mb_full_window_height_vrt = window.innerHeight
      var mb_window_height_hrz = window.innerWidth
      var mb_full_window_height_hrz = window.innerWidth
      var original_orientation = orientationCheck()
      if (original_orientation === 'horizontal') {
        mb_window_height_hrz = window.innerHeight
        mb_full_window_height_hrz = window.innerHeight
        mb_window_height_vrt = window.innerWidth
        mb_full_window_height_vrt = window.innerWidth
      }
      var browser_bar

      var device_in_use = 'tablet'
      var mobile_screen_height
      var mobile_screen_width
      var event_type
      var original_device
      figureMobile('mouseup')
      function figureMobile(type) {
        //Find out if the modal is opened in computer or mobile device size screen
        //Type is the opening event type, click or touchend
        var mobile_device_height_limit = 1100
        var mobile_device_width_limit = 800
        var width_limit_phone = 450 //Everything under 450px is a phone.
        var width_limit_tablet = 800 //Everything under 800px and over 400px is a tablet.
        var device = undefined
        var device_width = undefined
        var device_height = undefined
        var mobile_count = 0
        var desktop_count = 0

        var current_height = window.innerHeight
        var current_width = window.innerWidth

        var current_orientation = 'vertical'
        if (current_width > current_height) {
          current_orientation = 'horizontal'
          var old = mobile_device_height_limit
          mobile_device_height_limit = mobile_device_width_limit
          mobile_device_width_limit = old
          original_orientation = 'horizontal'
        }

        if (current_height < mobile_device_height_limit) {
          device_height = 'mobile'
          mobile_count++
        } else {
          device_height = 'desktop'
          desktop_count++
        }
        if (current_width < mobile_device_width_limit) {
          device_width = 'mobile'
          mobile_count++
        } else {
          device_width = 'desktop'
          desktop_count++
        }

        if (type === 'click' || type === 'mousedown' || type === 'mouseup') {
          desktop_count++
        } else {
          mobile_count++
        }

        if (desktop_count > mobile_count) {
          device = 'desktop'
          //$('#dropdown_modal_frame').addClass("desktop");
          device_in_use = 'pc'
        } else {
          device = 'mobile'
          //$('#dropdown_modal_frame').removeClass("desktop");
          if (window.innerHeight < width_limit_phone || window.innerWidth < width_limit_phone) {
            device_in_use = 'phone'
          } else {
            device_in_use = 'tablet'
          }
          if (browser_bar === undefined) {
            browser_bar = window.innerHeight - parseInt($('.main-page-welcoming').css('height'))
            if (browser_bar < 0) {
              browser_bar = 0
            }
          }
        }

        mobile_screen_height = current_height
        mobile_screen_width = current_width

        // New addition for this purpose only. Force pc when mouse is in use or there is no android, iOS or windows phone in use.
        if (original_device !== undefined) {
          if (type === 'mousedown' || type === 'mouseup' || type === 'click') {
            device_in_use = 'pc'
            device = device_in_use
            $('body').removeClass('mobile-zoom')
          }
        } else {
          var system = figureSystem()
          if (system.android === true || system.ios === true || system.windows === true) {
          } else {
            device_in_use = 'pc'
            device = device_in_use
            $('body').removeClass('mobile-zoom')
          }
        }

        // Quick fix for tablet. Change tablet ui to pc ui
        var system_II = figureSystem()
        if (system_II.android === true || system_II.ios === true || system_II.windows === true) {
          // Quick fix tablet
          if (device_in_use === 'pc') {
            device_in_use = 'pc'
            device = 'pc'
            $('body').removeClass('mobile-zoom')
          }
          if (device_in_use === 'tablet') {
            device_in_use = 'pc'
            device = 'pc'
            $('body').removeClass('mobile-zoom')
          }
        }
        //console.log(device_in_use);
        // end quick fix

        if (original_device === undefined) {
          original_device = device
          if (device === 'mobile') {
            changeToMobile()
          }
        } else {
          if (device !== original_device && device === 'mobile') {
            changeToMobile()
          }
        }
      }

      function figureSystem() {
        var system = { android: false, ios: false, windows: false }
        // Check if system is iOS
        var iOS =
          (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
          !window.MSStream
        if (iOS === false) {
          // Check again, with older style
          iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
        }

        // Check if system is Android
        var android = false
        var android_count = navigator.userAgent.toLowerCase().indexOf('android') > -1
        if (android_count) {
          android = true
        }
        // Result

        if (android === true) {
          system.android = true
        }
        if (iOS === true) {
          system.ios = true
        }

        return system
      }

      var last_orientation
      function changeToMobile() {
        var navbar_bg_height = parseInt($('.main-page-navbar').css('min-height'))
        var gray_navbar_height = parseInt($('.greysmoke-navbar').css('height'))
        var white_navbar_height = navbar_bg_height
        var orientation = orientationCheck()

        // Page zoom
        $('body').addClass('mobile-zoom')

        var system = figureSystem()
        var browser = detectBrowser()
        var webkit = isWebkit()
        if (system.android === true && browser === 'firefox') {
          mobileFirefox()
          return
        }

        if (system.android === true && webkit === true) {
          mobileChrome()
          return
        }

        if (system.ios === true) {
          iosPhone()
          return
        }

        if (webkit === true) {
          mobileChrome()
          return
        } else {
          mobileFirefox()
          return
        }
      }

      function mobileNavbar() {
        if ($('.main-page-navbar').hasClass('mb-nabvar-bg-grey') === true) {
          // White navbar in mobile
          $('.main-page-navbar').removeClass('mb-nabvar-bg-grey')
          $('.mp-navbar').removeClass('mb-nabvar-grey')
          $('.main-page-navbar').addClass('mb-nabvar-bg-white')
          $('.mp-navbar').addClass('mb-nabvar-white')
        } else {
          // Grey navbar in mobile
          $('.main-page-navbar').removeClass('mb-nabvar-bg-white')
          $('.mp-navbar').removeClass('mb-nabvar-white')
          $('.main-page-navbar').addClass('mb-nabvar-bg-grey')
          $('.mp-navbar').addClass('mb-nabvar-grey')
        }
      }

      $(window).scrollTop(0)

      $(window).resize(function () {
        setWelcomingPicture()
        if (device_in_use !== 'pc') {
          changeToMobile()
        }
        if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
          figureFullWindowHeight()
        }
        if (device_in_use === 'pc') {
          pcResponsive()
        }
        if (
          $('.main-page-bg').hasClass('non-webkit') === true ||
          $('.main-page-bg').hasClass('ios') === true ||
          $('.main-page-bg').hasClass('chrome') === true
        ) {
          checkLayout()
        }
        if (device_in_use !== 'pc') {
          mobileModal()
        }
      })

      $(window).on('orientationchange', function (event) {
        setWelcomingPicture()
        if (device_in_use !== 'pc') {
          changeToMobile()
        }
        if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
          figureFullWindowHeight()
        }
        if (device_in_use === 'pc') {
          pcResponsive()
        }
        if (
          $('.main-page-bg').hasClass('non-webkit') === true ||
          $('.main-page-bg').hasClass('ios') === true ||
          $('.main-page-bg').hasClass('chrome') === true
        ) {
          checkLayout()
        }
        if (device_in_use !== 'pc') {
          mobileModal()
        }
      })

      var first_scroll
      $(window).scroll(function () {
        var navbar_offset = $('.main-page-navbar').offset()
        if (navbar_offset.top > 30) {
          $('.mp-navbar').removeClass('greysmoke-navbar')
          $('.mp-navbar').addClass('whitesmoke-navbar')
          $('.navbar-logo').addClass('white-navbar-logo')
          if ($('.main-page-navbar').hasClass('mb-nabvar-bg-grey') === true) {
            mobileNavbar()
          }
        } else {
          $('.mp-navbar').removeClass('whitesmoke-navbar')
          $('.mp-navbar').addClass('greysmoke-navbar')
          $('.navbar-logo').removeClass('white-navbar-logo')
          if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
            mobileNavbar()
          }
        }
        if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
          figureFullWindowHeight()
        }

        if (device_in_use === 'pc' && first_scroll === undefined) {
          pcResponsive()
          first_scroll = false
        }
      })

      $(window).on('mousedown touchstart', function (e) {
        //e.preventDefault();
        if (event_type === undefined) {
          figureMobile(e.type)
          event_type = e.type
        }
      })

      var permit_to_close = true
      $('.login-btn').on('mouseup touchend', function (e) {
        e.preventDefault()
        openCloseLoginModal()
      })
      $('.login-modal-bg').on('mousedown touchstart', function () {
        permit_to_close = true
      })
      $('.login-modal-bg').on('mouseup touchend', function (e) {
        if (e.target.id === 'login-modal-bg' && permit_to_close === true) {
          openCloseLoginModal()
        }
      })
      $('.login-modal-bg').scroll(function () {
        permit_to_close = false
      })
      $('.login-modal-close-btn').on('mouseup touchend', function () {
        openCloseLoginModal()
      })

      var touch_start
      var touch_end
      var moving
      $('.sheet-change-btn').on('touchstart touchend', function (e) {
        var out = { x: 0, y: 0 }
        if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
          var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0]
          out.x = touch.pageX
          out.y = touch.pageY
        } else if (
          e.type == 'mousedown' ||
          e.type == 'mouseup' ||
          e.type == 'mousemove' ||
          e.type == 'mouseover' ||
          e.type == 'mouseout' ||
          e.type == 'mouseenter' ||
          e.type == 'mouseleave'
        ) {
          out.x = e.pageX
          out.y = e.pageY
        }

        if (touch_start === undefined) {
          touch_start = out.x
        } else if (touch_start !== undefined && touch_end === undefined) {
          touch_end = out.x
        }
        if (touch_start !== undefined && touch_end !== undefined) {
          moving = touch_end - touch_start
        }
      })

      $('.sheet-change-btn').on('mouseup touchend', function (e) {
        e.preventDefault()
        var permission_to_scroll = true
        if (e.type === 'touchend') {
          if (moving > 3 || moving < -3) {
            permission_to_scroll = false
          }
          touch_start = undefined
          touch_end = undefined
          moving = undefined
        }

        if (permission_to_scroll === false) {
          // Don't scroll
        } else {
          var phone_navbar_height = 55
          var position = window.innerHeight
          position =
            position -
            parseInt($('.main-page-navbar').css('min-height')) -
            parseInt($('.sheet-change-btn').css('height'))
          if ($('.information-area').hasClass('mb-information-area') === true) {
            $('.information-area').addClass('mb-information-area')
            position = window.innerHeight
            phone_navbar_height = 170
            position = position - phone_navbar_height
          } else {
            if (e.target.id === 'sheet-right') {
              var parent_width = parseInt($('.left-column').parent().css('width'))
              var new_offset_left = (parent_width - parseInt($('.info-rectangle').css('width'))) / 2
              $('.left-column').css({ left: new_offset_left })
            } else {
              $('.left-column').css({ left: '0px' })
            }
          }

          var current_btn_offset = $('.sheet-change-btn').offset().top
          var current_scroll_n_navbar = $(window).scrollTop() + phone_navbar_height
          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            //current_scroll_n_navbar = $('.main-page-bg').scrollTop() + phone_navbar_height;
            current_scroll_n_navbar = $('.main-page-bg').scrollTop()
          }
          if (current_scroll_n_navbar > current_btn_offset) {
            // Move upwards
            // The current scroll is over the sheet btn top line
            if ($('.information-area').hasClass('mb-information-area') === true) {
              //Change position, if phone browser bar has vanished
              var browser_bar_height = mb_full_window_height_vrt - mb_window_height_vrt
              var this_window_height = mb_window_height_vrt
              var orientation = orientationCheck()
              if (orientation === 'horizontal') {
                browser_bar_height = mb_full_window_height_hrz - mb_window_height_hrz
                this_window_height = mb_window_height_hrz
              }
              if (window.innerHeight > this_window_height) {
                position = window.innerHeight - phone_navbar_height - browser_bar_height
              }
            }
            $(window).scrollTop(position)
            if (
              $('.main-page-bg').hasClass('non-webkit') === true ||
              $('.main-page-bg').hasClass('ios') === true ||
              $('.main-page-bg').hasClass('chrome') === true
            ) {
              position =
                parseInt($('.main-page-welcoming').css('height')) -
                parseInt($('.sheet-change-btn').css('height')) -
                phone_navbar_height

              var main_page_offset =
                parseInt($('.main-page-information').css('top')) +
                parseInt($('.main-page-information').css('margin-top'))
              position = main_page_offset - 85

              $('.main-page-bg').scrollTop(position)
            }
          } else {
            if (current_scroll_n_navbar < current_btn_offset - 1) {
              // Move downwards
              position = $(window).scrollTop()
              if (
                $('.main-page-bg').hasClass('non-webkit') === true ||
                $('.main-page-bg').hasClass('ios') === true ||
                $('.main-page-bg').hasClass('chrome') === true
              ) {
                position = $('.main-page-bg').scrollTop()
              }
              var mover = setInterval(moverTimer, 8)
            } else {
              // No need to move
            }
          }

          $('.sheet-change-btn').addClass('sheet-change-unselect')
          $('.sheet-change-btn').removeClass('sheet-change-selected')
          if ($(this).hasClass('sheet-change-left') === true) {
            $('.sheet-change-left').removeClass('sheet-change-unselect')
            $('.sheet-change-left').addClass('sheet-change-selected')

            $('.student').css({ display: 'none' })
            $('.driving-school').css({ display: 'block' })
          } else {
            $('.sheet-change-right').removeClass('sheet-change-unselect')
            $('.sheet-change-right').addClass('sheet-change-selected')

            $('.driving-school').css({ display: 'none' })
            $('.student').css({ display: 'block' })
          }

          if (device_in_use === 'pc') {
            $('.student').css({ display: 'none' })
            $('.driving-school').css({ display: 'block' })
            $('.left-column').css({ left: '0px' })
            pcResponsive()
          }

          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            mobileSheetChange()
          }

          var right_column_insert = $('.right-column')
            .children('.info-rectangle')
            .children('.rectangle-text')
            .css('display')
          if (right_column_insert === 'none') {
            $('.right-column').css({ display: 'none' })
          } else {
            $('.right-column').css({ display: 'inline-block' })
          }
        }

        function moverTimer() {
          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            position = position + 16
          } else {
            position = position + 8
          }
          var navbar_offset = $('.main-page-navbar').offset()
          var navbar_bottom = navbar_offset.top + phone_navbar_height
          var sheet_btn_offset = $('.sheet-change-btn').offset()
          if (sheet_btn_offset.top <= navbar_bottom) {
            position = navbar_bottom - phone_navbar_height
            $(window).scrollTop(position)

            clearInterval(mover)
            return
          }
          $(window).scrollTop(position)
          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            $('.main-page-bg').scrollTop(position)
            var main_page_offset =
              parseInt($('.main-page-information').css('top')) + parseInt($('.main-page-information').css('margin-top'))
            main_page_offset = main_page_offset - 85
            if (position >= main_page_offset - 7) {
              $('.main-page-bg').scrollTop(main_page_offset)
              clearInterval(mover)
              return
            }
          }
        }
      })

      $('.navbar-logo').on('mouseup touchend', function () {
        $(window).scrollTop(0)
        if (
          $('.main-page-bg').hasClass('non-webkit') === true ||
          $('.main-page-bg').hasClass('ios') === true ||
          $('.main-page-bg').hasClass('chrome') === true
        ) {
          $('.main-page-bg').scrollTop(0)
        }
      })

      //Move up and down in preferences with arrows - start
      $('.arrow').on('mousedown touchstart', function () {
        var down = $(this).hasClass('arrow-down')
        if (down === true) {
          mouseDown('down')
        } else {
          mouseDown('up')
        }
      })
      $('.arrow').on('mouseup touchend', function () {
        var down = $(this).hasClass('arrow-down')
        if (down === true) {
          mouseUp('down')
        } else {
          mouseUp('up')
        }
      })
      var mousedownID = 0 //Global ID of mouse down interval
      var original_scroll = checkScroll()
      var scroll_done = false
      function mouseDown(direction) {
        if (mousedownID === 0) {
          if (direction === 'down') {
            mousedownID = setInterval(moveDown, 100 /*execute every 100ms*/)
          } else {
            mousedownID = setInterval(moveUp, 100 /*execute every 100ms*/)
          }
        }
      }
      function mouseUp(direction) {
        if (mousedownID !== 0) {
          //Only stop if exists
          clearInterval(mousedownID)
          mousedownID = 0

          var current_scroll = checkScroll()
          if (current_scroll === original_scroll && scroll_done === false) {
            if (direction === 'down') {
              moveDown()
            } else {
              moveUp()
            }
            return
          }
          scroll_done = false
        }
      }
      function moveDown() {
        scrollPreferences('down')
        scroll_done = true
      }
      function moveUp() {
        scrollPreferences('up')
        scroll_done = true
      }
      //Move up and down in preferences with arrows - end

      setWelcomingPicture()
      function setWelcomingPicture() {
        $('.welcoming-photo').css({ width: '100%' })
        $('.welcoming-photo').css({ height: 'auto' })

        var current_pic_width = parseInt($('.welcoming-photo').css('width'))
        var current_pic_height = parseInt($('.welcoming-photo').css('height'))

        var width_perc = 100
        var height_perc = (current_pic_height * width_perc) / current_pic_width

        var set_pic_height = current_pic_width * (0.01 * height_perc)
        $('.welcoming-photo').css({ height: set_pic_height })

        current_pic_height = parseInt($('.welcoming-photo').css('height'))

        var bg_height = parseInt($('.main-page-welcoming').css('height'))
        if (current_pic_height < bg_height) {
          var growth_perc = (bg_height * 100) / current_pic_height
          $('.welcoming-photo').css({ height: bg_height })
          var set_pic_width = current_pic_width * (0.01 * growth_perc)
          $('.welcoming-photo').css({ width: set_pic_width })
        } else if (bg_height < current_pic_height) {
          var shrink_perc = (bg_height * 100) / current_pic_height
          $('.welcoming-photo').css({ height: bg_height })
          var set_pic_width = current_pic_width * (0.01 * shrink_perc)
          $('.welcoming-photo').css({ width: set_pic_width })
        }
        current_pic_width = parseInt($('.welcoming-photo').css('width'))
        var bg_width = parseInt($('.main-page-welcoming').css('width'))
        if (current_pic_width < bg_width) {
          $('.welcoming-photo').css({ width: bg_width })
          var set_pic_height = bg_width * (0.01 * height_perc)
          $('.welcoming-photo').css({ height: set_pic_height })
        }
      }

      var solid_information_vrt_height
      var solid_information_hrz_height
      var solid_preferences_vrt_top
      var solid_preferences_hrz_top
      layoutInit()
      function layoutInit() {
        // Initializes rectangle size for the page
        var orientation = orientationCheck()
        $('.row-textarea').each(function () {
          var left_height = parseInt($(this).children('.left-column').css('height'))
          var right_height = parseInt($(this).children('.left-column').css('height'))
          if (left_height > right_height) {
            if (left_height !== null) {
              var rectangle_height = parseInt(
                $(this).children('.left-column').children('.info-rectangle').css('height')
              )
              $(this).children('.right-column').children('.info-rectangle').css({ height: rectangle_height })
              $(this).css({ height: left_height })
            }
          } else {
            if (right_height !== null) {
              var rectangle_height = parseInt(
                $(this).children('.right-column').children('.info-rectangle').css('height')
              )
              $(this).children('.left-column').children('.info-rectangle').css({ height: rectangle_height })
              $(this).css({ height: right_height })
            }
          }
          if ($(this).children('.left-column').hasClass('mb-rectangle-width') === true) {
            if (mb_init_vertical_done === false) {
              $('.left-column').parent().css({ top: 30 })
              var right_column_offset = parseInt($('.left-column').css('height')) + 20
              $('.right-column').css({ top: right_column_offset })

              var right_column_top = parseInt($('.right-column').css('top'))
              var new_row_height = parseInt($('.mb-rectangle-width').css('height')) + right_column_top
              $(this).css({ height: new_row_height })
            }
          }
        })

        //WILL DO WITH PC TOO IF PAGE IS RIGHT SIZE AND USER REFRESHES IT?? DOES IT STILL DO IT 3.6.2020?
        if (orientation === 'vertical' && device_in_use !== 'pc') {
          var area_needed = 0
          $('.information-area')
            .children('.information-wrapper')
            .children('.info-row')
            .each(function () {
              area_needed = area_needed + parseInt($(this).css('height'))
              area_needed = area_needed + parseInt($(this).css('margin-bottom'))
            })
          var addition_space = 110

          $('.information-area').css({ height: area_needed })
          area_needed = area_needed + parseInt($('.info-sheet-change-bg').css('height')) + addition_space
          $('.main-page-information').css({ height: area_needed })
          $('.information-area').css({ height: area_needed })

          var new_preferences_top =
            $('.main-page-information').offset().top + parseInt($('.main-page-information').css('height')) + 80 //Tähän vaihtaa se yläpalkin osa?
          if (solid_preferences_vrt_top === undefined) {
            if (solid_preferences_hrz_top !== undefined) {
              // Code erased because no need anymore cause the window scroll top when turning device.
            }
            solid_information_vrt_height = area_needed
            solid_preferences_vrt_top = new_preferences_top
          } else {
            new_preferences_top = solid_preferences_vrt_top
          }
          $('.main-page-preferences').css({ top: new_preferences_top })
          var footer_offset = new_preferences_top + parseInt($('.main-page-preferences').css('height'))
          $('.main-page-footer').css({ top: footer_offset })

          mb_init_vertical_done = true
        } else if (orientation === 'horizontal' && device_in_use !== 'pc') {
          var area_needed = 0
          $('.information-area')
            .children('.information-wrapper')
            .children('.info-row')
            .each(function () {
              area_needed = area_needed + parseInt($(this).css('height'))
              area_needed = area_needed + parseInt($(this).css('margin-bottom'))
            })
          var addition_space = 160

          $('.information-area').css({ height: area_needed })
          area_needed = area_needed + parseInt($('.info-sheet-change-bg').css('height')) + addition_space
          $('.main-page-information').css({ height: area_needed })
          $('.information-area').css({ height: area_needed })

          var new_preferences_top =
            $('.main-page-information').offset().top + parseInt($('.main-page-information').css('height')) + 80 //Tähän vaihtaa se yläpalkin osa?
          if (solid_preferences_hrz_top === undefined) {
            solid_information_hrz_height = area_needed
            solid_preferences_hrz_top = new_preferences_top
          } else {
            new_preferences_top = solid_preferences_hrz_top
          }
          $('.main-page-preferences').css({ top: new_preferences_top })
          var footer_offset = new_preferences_top + parseInt($('.main-page-preferences').css('height'))
          $('.main-page-footer').css({ top: footer_offset })
          mb_init_horizontal_done = true
        } else {
          //PC
          pcResponsive()

          var current_info_height = parseInt($('.main-page-information').css('height'))
          var new_info_height = current_info_height - parseInt($('.main-page-navbar').css('min-height'))
          $('.main-page-information').css({ height: new_info_height })
        }
      }

      var overlapping_limit
      var column_left_height
      var column_right_height
      var original_info_height
      var levered_rectangles = false
      function pcResponsive() {
        // Fix titles
        var title_width = $('.title-text').innerWidth()
        var title_overflow = $('.title-text')[0].scrollWidth

        if (title_overflow > title_width) {
          title_width = title_overflow + 15
        }
        var title_bg_width = $('.row-title').innerWidth()
        var title_leaftext_width = title_bg_width - title_width
        $('.title-leaflet-text').css({ width: title_leaftext_width })
        $('.title-text').css({ width: title_width })

        var sheet_btn_width = parseInt($('.sheet-change-left').css('width'))
        var info_padding = parseInt($('.information-wrapper').css('padding-left'))
        var title_max_width = sheet_btn_width - info_padding

        var full_title_height = parseInt($('.title-leaflet-text').css('height'))

        if (title_width > title_max_width) {
          var new_offset = parseInt($('.title-text').css('height'))

          $('.title-leaflet-text').css({ top: new_offset })
          $('.title-leaflet-text').css({ left: '0px' })
          $('.title-leaflet-text').css({ width: '100%' })

          full_title_height =
            parseInt($('.title-leaflet-text').css('height')) + parseInt($('.title-text').css('height'))
          $('.title-text').css({ 'min-height': '80px' })
          $('.title-leaflet-text').css({ 'min-height': '80px' })
          $('.title-leaflet-text').css({ 'padding-top': '0px' })
          $('.title-leaflet-text').css({ 'margin-top': '-10px' })
        } else {
          $('.title-leaflet-text').css({ width: '100%' })
          var full_length = parseInt($('.title-leaflet-text').css('width'))
          var new_length = full_length - title_width
          $('.title-leaflet-text').css({ width: new_length })

          $('.title-leaflet-text').css({ top: '0px' })
          $('.title-leaflet-text').css({ left: 'auto' })
          $('.title-leaflet-text').css({ right: '0px' })

          $('.title-text').css({ 'min-height': '100%' })
          $('.title-leaflet-text').css({ 'min-height': '100%' })
          $('.title-leaflet-text').css({ 'padding-top': '15px' })
          $('.title-leaflet-text').css({ 'margin-top': '0px' })
        }
        $('#leaflet-II').css({ 'padding-bottom': '20px' })

        if (parseInt($('.title-leaflet-text').children('.orange-font').css('height')) > 38) {
          $('.title-leaflet-text').children('.orange-font').css({ 'padding-bottom': '15px' })
        } else {
          $('.title-leaflet-text').children('.orange-font').css({ 'padding-bottom': '0px' })
        }
        $('.title-leaflet-text').parent('.row-title').css({ height: full_title_height })

        var titles_check = parseInt($('.title-leaflet-text').css('height'))
        var leaflets_check = 0
        $('.title-leaflet-text')
          .children('.subtitle-font')
          .each(function () {
            leaflets_check = leaflets_check + parseInt($(this).css('height')) + parseInt($(this).css('padding-bottom'))
          })
        if (leaflets_check < titles_check - 2) {
          $('.title-leaflet-text').css({ 'min-height': '80px' })
          $('.title-leaflet-text').css({ height: leaflets_check })
        } else {
          $('.title-leaflet-text').css({ height: 'auto' })
        }
        if (parseInt($('.title-leaflet-text').css('left')) !== 0) {
          var new_leaflet_height = parseInt($('.title-leaflet-text').css('height'))
          $('.row-title').css({ height: new_leaflet_height })
        }

        var title_text = parseInt($('.title-text').css('height'))
        var row_title = parseInt($('.row-title').css('height'))
        if (title_text > row_title) {
          $('.row-title').css({ height: title_text })
          $('.title-leaflet-text').addClass('padding-fix')
          var new_padding = title_text * 0.3
          $('.padding-fix').css({ 'padding-top': new_padding })
        } else {
          $('.title-leaflet-text').removeClass('padding-fix')
        }

        //Fix rectangles

        $('.student').css({ display: 'none' })
        $('.driving-school').css({ display: 'block' })

        if (original_info_height === undefined) {
          var new_height = window.innerHeight - parseInt($('.whitesmoke-navbar').css('height'))
          $('.main-page-information').css({ height: new_height })
          original_info_height = parseInt($('.main-page-information').css('height'))
        }

        overlapping_limit = 650
        if (window.innerWidth > overlapping_limit) {
          $('.info-rectangle').css({ width: '95%' })
          $('.left-column').css({ width: '50%' })
          $('.right-column').css({ width: '50%' })

          if (parseInt($('.info-rectangle').css('width')) > 500) {
            $('.info-rectangle').css({ width: 500 })
            var bg_width = parseInt($('.left-column').css('width')) * 2
            var offset_new = (bg_width - 500 * 2) / 4
            $('.left-column').children('.info-rectangle').css({ 'margin-left': offset_new })
            $('.right-column').children('.info-rectangle').css({ 'margin-right': offset_new })
          } else {
            $('.left-column').children('.info-rectangle').css({ 'margin-left': 0 })
            $('.right-column').children('.info-rectangle').css({ 'margin-right': 0 })
          }

          $('.left-column').css({ height: '' })
          $('.right-column').css({ height: '' })
          $('.info-rectangle').css({ height: '' })

          var left_height = parseInt($('.left-column').children('.info-rectangle').css('height'))
          var right_height = parseInt($('.right-column').children('.info-rectangle').css('height'))
          if (left_height !== right_height) {
            left_height = right_height
            $('.left-column').children('.info-rectangle').css({ height: left_height })
          }

          //$('.right-column').children('.info-rectangle').css({"top":"0px"});
          $('.right-column').css({ top: '0px' })

          levered_rectangles = false
        } else {
          $('.left-column').children('.info-rectangle').css({ 'margin-left': 0 })
          $('.right-column').children('.info-rectangle').css({ 'margin-right': 0 })

          $('.info-rectangle').css({ width: '100%' })
          $('.left-column').css({ width: '100%' })
          $('.right-column').css({ width: '100%' })

          $('.left-column').css({ height: '' })
          $('.right-column').css({ height: '' })
          $('.info-rectangle').css({ height: '' })

          var left_height = parseInt($('.left-column').children('.info-rectangle').css('height'))
          var right_height = parseInt($('.right-column').children('.info-rectangle').css('height'))
          if (left_height !== right_height) {
            left_height = right_height
            $('.left-column').children('.info-rectangle').css({ height: left_height })
          }

          var right_column_offset = left_height + 40

          $('.right-column').css({ top: right_column_offset })

          levered_rectangles = true
        }

        // Handle sheet change for PC.
        column_left_height = parseInt($('.left-column').children('.info-rectangle').css('height'))
        column_right_height = parseInt($('.right-column').children('.info-rectangle').css('height'))
        if ($('.sheet-change-right').hasClass('sheet-change-selected')) {
          $('.right-column').css({ visibility: 'hidden' })
          $('.left-column').children('.info-rectangle').children('.driving-school').css({ display: 'none' })
          $('.left-column').children('.info-rectangle').children('.student').css({ display: 'block' })
          $('.left-column').children('.info-rectangle').css({ height: column_left_height })
          var parent_width = parseInt($('.left-column').parent().css('width'))
          var new_offset_left = (parent_width - parseInt($('.info-rectangle').css('width'))) / 2
          $('.left-column').css({ left: new_offset_left })

          $('.row-title').children('.driving-school').css({ display: 'none' })
          $('.row-title').children('.student').css({ display: 'block' })
          $('.title-leaflet-text').children('.driving-school').css({ display: 'none' })
          $('.title-leaflet-text').children('.student').css({ display: 'block' })
          $('#leaflet-II').css({ visibility: 'hidden' })

          $('.left-column').children('.info-rectangle').css({ 'margin-left': 0 })
        } else {
          $('.right-column').css({ visibility: 'visible' })
          $('.left-column').children('.info-rectangle').children('.student').css({ display: 'none' })
          $('.left-column').children('.info-rectangle').children('.driving-school').css({ display: 'block' })

          $('.left-column').css({ left: '0px' })

          $('.row-title').children('.student').css({ display: 'none' })
          $('.row-title').children('.driving-school').css({ display: 'block' })

          $('.title-leaflet-text').children('.student').css({ display: 'none' })
          $('.title-leaflet-text').children('.driving-school').css({ display: 'block' })

          $('#leaflet-II').css({ visibility: 'visible' })
        }

        // Calculate needed space
        var left_rec_height = parseInt($('.left-column').children('.info-rectangle').css('height'))
        var right_rec_height = parseInt($('.right-column').children('.info-rectangle').css('height'))
        var rec_add_space = 4
        var rectangles_height = left_rec_height + rec_add_space

        if (levered_rectangles === true) {
          var right_rec_offset = parseInt($('.right-column').css('top'))
          rectangles_height = left_rec_height + right_rec_offset + rec_add_space
        }
        $('.left-column').parent('.row-textarea').css({ height: rectangles_height })

        var margin_top_height = parseInt($('.info-top-margin').css('height'))
        var separator_height = parseInt($('.row-separator').css('height'))
        var title_section_height = parseInt($('.row-title').css('height'))

        var content_margins = 11
        var content_height =
          margin_top_height + separator_height + title_section_height + rectangles_height + content_margins
        $('.information-area').children('.information-wrapper').css({ height: content_height })
        var area_height = content_height + parseInt($('.info-sheet-change-bg').css('height'))
        var extra_space_bottom = 60
        var full_window = window.innerHeight - parseInt($('.main-page-navbar').css('min-height'))
        if (area_height < full_window - 5) {
          area_height = full_window
        } else {
          area_height = area_height + extra_space_bottom
        }
        $('.information-area').css({ height: area_height })
        $('.main-page-information').css({ height: area_height })

        // Preferences fix
        var pref_height = full_window
        $('#preferences-information-wrapper').css({ height: pref_height })
        $('.main-page-preferences').css({ height: pref_height })

        var arrows = 0
        $('.arrow-bg').each(function () {
          arrows = arrows + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
        })
        var pref_wrapper = parseInt($('#preferences-information-wrapper').css('height')) - arrows - 18
        $('.preferences-wrapper').css({ height: pref_wrapper })

        var pref_top = area_height + parseInt($('.main-page-information').css('top'))
        $('.main-page-preferences').css({ top: pref_top })

        // - - -
        $('.preferences-bg').children('.row-textarea').css({ 'min-height': 220 })

        controlArrows()
        // - - -

        var pref_overlapping_limit = 750
        if (window.innerWidth < pref_overlapping_limit) {
          $('.preferences-title').css({ width: '100%' })
          $('.preferences-title').css({ 'padding-right': '0px' })
          $('.preferences-title').css({ 'margin-top': '0px' })
          $('.preferences-title').css({ height: 'auto' })

          var text_top = parseInt($('.preferences-title').css('height'))
          $('.preferences-text-bg').css({ width: '100%' })
          $('.preferences-text-bg').css({ 'margin-top': text_top })
          //$('.preferences-text-bg').css({"height":"auto"});

          var text_height = parseInt($('.preferences-text-bg').parent('.row-textarea').css('height')) - text_top
          $('.preferences-text-bg').css({ height: text_height })
          $('.preferences-text-bg').css({ 'padding-top': '0px' })
          $('.preferences-text-bg').each(function () {
            var bg_height = parseInt($(this).css('height'))
            var text_height = 0
            $(this)
              .children('.preferences-text')
              .each(function () {
                text_height = text_height + parseInt($(this).css('height')) + parseInt($(this).css('margin-top'))
              })

            var padding_top = parseInt($(this).css('padding-top'))
            var offset_top = ((bg_height - text_height) / 2 - padding_top) * 0.6
            $(this).css({ 'padding-top': offset_top })
          })
        } else {
          $('.preferences-title').css({ width: '37%' })
          $('.preferences-text-bg').css({ width: '63%' })

          $('.preferences-title').css({ 'padding-right': '8px' })
          $('.preferences-title').css({ 'margin-top': '0px' })
          $('.preferences-title').css({ height: 'auto' })

          $('.preferences-text-bg').css({ 'margin-top': '0px' })
          $('.preferences-text-bg').css({ height: '100%' })

          $('.preferences-title').each(function () {
            var bg_height = parseInt($(this).parent('.row-textarea').css('height'))
            var text_height = parseInt($(this).css('height'))
            var padding_top = parseInt($(this).css('padding-top'))
            var offset_top = ((bg_height - text_height - padding_top) / 2) * 0.8

            $(this).css({ 'margin-top': offset_top })
          })
          $('.preferences-text-bg').each(function () {
            var bg_height = parseInt($(this).css('height'))
            var text_height = 0
            $(this)
              .children('.preferences-text')
              .each(function () {
                text_height = text_height + parseInt($(this).css('height')) + parseInt($(this).css('margin-top'))
              })
            var offset_top = ((bg_height - text_height) / 2) * 0.9
            $(this).css({ 'padding-top': offset_top })
          })
        }

        // Footer fix
        var footer_top = pref_top + parseInt($('.main-page-preferences').css('height'))
        $('.main-page-footer').css({ top: footer_top })

        if (window.innerWidth < pref_overlapping_limit) {
          $('.footer-column').addClass('pc-column')
          $('.footer-logo').addClass('footer-smaller-logo')
        } else {
          $('.footer-column').removeClass('pc-column')
          $('.footer-logo').removeClass('footer-smaller-logo')
        }

        // Login modal fix
        pcModal()

        // Quick fix for mobile tablet layout
        var system = figureSystem()
        var browser = detectBrowser()
        var webkit = isWebkit()

        if (system.android === true || system.ios === true) {
          mobileTablet()
        }
      }

      function pcModal() {
        // Login modal fix
        var modal_width_limit = 850
        var modal_height_limit = 555

        var width_perc = 0.5
        var left_perc = 0.25
        var height_perc = 0.9

        var fields_top_perc = 0.1
        var new_fields_top = 40
        $('.login-fields').css({ 'margin-top': new_fields_top })

        var new_modal_width = window.innerWidth * width_perc
        var new_modal_left = window.innerWidth * left_perc
        if (window.innerWidth < modal_width_limit) {
          new_modal_width = modal_width_limit * width_perc
          new_modal_left = (window.innerWidth - modal_width_limit * width_perc) / 2
          if (window.innerWidth < modal_width_limit * width_perc + 70) {
            new_modal_left = new_modal_left * 0.7
          }
        }
        if (new_modal_width > 900) {
          new_modal_width = 900
          new_modal_left = (window.innerWidth - new_modal_width) / 2
        }
        $('.login-modal-frame').css({ width: new_modal_width })
        $('.login-modal-frame').css({ left: new_modal_left })

        $('#login-email-field').css({ display: 'block' })
        $('#login-password-field').css({ display: 'block' })
        var email_width = parseInt($('#login-email-field').css('width'))
        var password_width = parseInt($('#login-password-field').css('width'))
        var fields_width = parseInt($('.login-fields').css('width'))
        var email_offset_left = (fields_width - email_width) / 2
        var password_offset_left = (fields_width - password_width) / 2
        $('#login-email-field').css({ left: email_offset_left })
        $('#login-password-field').css({ left: password_offset_left })
        //$('#login-email-field').css({"left":"25%"});

        var new_modal_height = window.innerHeight * height_perc
        if (window.innerHeight < modal_height_limit) {
          new_modal_height = modal_height_limit * height_perc
          if (window.innerHeight < 500) {
            $('.login-modal-bg').css({ 'overflow-x': 'scroll' })
          } else {
            $('.login-modal-bg').css({ 'overflow-x': 'hidden' })
          }
        }
        var margin_top = 0
        if (new_modal_height > 800) {
          margin_top = ((new_modal_height - 800) / 2) * 0.9
          new_modal_height = 800
        }
        $('.login-modal-frame').css({ height: new_modal_height })
        $('.login-modal-frame').css({ 'margin-top': margin_top })

        if (window.innerHeight > modal_height_limit) {
          new_fields_top = parseInt($('.login-fields-wrapper').css('height')) * fields_top_perc
          if (new_fields_top < 40) {
            new_fields_top = 40
          }
          $('.login-fields').css({ 'margin-top': new_fields_top })
        }
      }

      function mobileTablet() {
        var orientation = orientationCheck()

        if (orientation === 'vertical') {
          var new_area_height = parseInt($('.information-wrapper').css('height')) + 130
          $('.information-area').css({ height: new_area_height })
          $('.main-page-information').css({ height: new_area_height })

          $('.main-page-preferences').css({ height: new_area_height })
          $('.main-page-preferences').children('.information-wrapper').css({ height: new_area_height })

          var new_pref_wrapper = new_area_height - parseInt($('.arrow-bg').css('height')) * 2 - 30
          $('.preferences-wrapper').css({ height: new_pref_wrapper })
          controlArrows()
          checkLayout()
        } else {
        }
      }

      function mobileChrome() {
        $('body').removeClass('mobile-zoom')
        $('body').addClass('mb-chrome-zoom')
        $('.main-page-bg').addClass('chrome')

        $('.main-page-bg').css({ position: 'fixed' })
        $('.main-page-bg').css({ 'overflow-y': 'scroll' })
        $('.main-page-bg').css({ 'overflow-x': 'hidden' })

        mobileNavbarBasic()
        mobileNavbarNew()

        setWelcomingPicture()
        mobileInformation()
        mobilePreferences()
        mobileFooter()
        checkLayout()
      }
      function mobileFirefox() {
        $('body').removeClass('mobile-zoom')
        $('body').addClass('mb-firefox-zoom')
        $('.main-page-bg').addClass('non-webkit')

        $('.main-page-bg').css({ position: 'fixed' })
        $('.main-page-bg').css({ 'overflow-y': 'scroll' })
        $('.main-page-bg').css({ 'overflow-x': 'hidden' })

        mobileNavbarBasic()
        mobileNavbarNew()

        setWelcomingPicture()
        mobileInformation()
        mobilePreferences()
        mobileFooter()
        checkLayout()
      }
      function iosPhone() {
        $('body').removeClass('mobile-zoom')
        $('body').addClass('mb-ios-zoom')
        $('.main-page-bg').addClass('ios')

        $('.main-page-bg').css({ position: 'fixed' })
        $('.main-page-bg').css({ 'overflow-y': 'scroll' })
        $('.main-page-bg').css({ 'overflow-x': 'hidden' })

        mobileNavbarBasic()
        mobileNavbarNew()

        setWelcomingPicture()
        mobileInformation()
        mobilePreferences()
        mobileFooter()
        checkLayout()
      }

      function mobileNavbarBasic() {
        // Navbar
        $('.main-page-navbar').addClass('mb-nabvar-bg-grey')
        $('.greysmoke-navbar').addClass('mb-nabvar-grey')
        $('.whitesmoke-navbar').addClass('mb-nabvar-white')
        $('.navbar-login-btn').addClass('wider-btn')
        $('.navbar-login-btn').addClass('mb-login-btn-firefox')
      }
      function mobileNavbarNew() {
        if ($('.mp-navbar').hasClass('mb-nabvar-white')) {
          $('.main-page-navbar').addClass('mb-nabvar-bg-white')
          $('.whitesmoke-navbar').addClass('mb-nabvar-white')
        } else {
          $('.main-page-navbar').addClass('mb-nabvar-bg-grey')
          $('.whitesmoke-navbar').addClass('mb-nabvar-grey')
        }

        var orientation = orientationCheck()

        // For iphone
        if ($('.main-page-bg').hasClass('ios') === true) {
          var forcing_for_iphone = parseInt($('.info-sheet-change-bg').offset().top)
          if (forcing_for_iphone < 100) {
            $('.mp-navbar').addClass('force-white')
            $('.navbar-logo').addClass('force-white-navbar-logo')
            $('.mp-navbar').addClass('force-mb-nabvar-white')
          }
        }
      }

      function mobileInformation() {
        // Information
        var orientation = orientationCheck()
        var full_window = window.innerHeight
        if (orientation !== last_orientation) {
        }

        if (orientation === 'vertical') {
          $('.info-sheet-change-bg').css({ height: '100px' })

          $('.main-page-information').css({ 'margin-top': '-100px' })
          $('.main-page-information').children('.information-area').css({ 'padding-top': '100px' })
          $('.main-page-preferences').css({ 'margin-top': '-100px' })
          $('.main-page-footer').css({ 'margin-top': '-100px' })

          $('.sheet-change-btn').css({ 'font-size': '19pt' })
          $('.sheet-change-btn').css({ 'padding-top': '42px' })
        } else {
          $('.info-sheet-change-bg').css({ height: '80px' })

          $('.main-page-information').css({ 'margin-top': '-80px' })
          $('.main-page-information').children('.information-area').css({ 'padding-top': '80px' })
          $('.main-page-preferences').css({ 'margin-top': '-80px' })
          $('.main-page-footer').css({ 'margin-top': '-80px' })

          $('.sheet-change-btn').css({ 'font-size': '24pt' })
          $('.sheet-change-btn').css({ 'padding-top': '32px' })
        }

        var title = $('.main-page-information')
          .children('.information-area')
          .children('.information-wrapper')
          .children('.row-title')
        var title_text = title.children('.title-text')
        var leaflet_text = title.children('.title-leaflet-text')
        title_text.css({ width: '100%' })
        title_text.css({ 'min-height': '1%' })
        title_text.css({ 'line-height': '45px' })
        var title_height = parseInt(title_text.css('height'))
        leaflet_text.css({ width: '100%' })
        leaflet_text.css({ top: title_height })
        leaflet_text.css({ 'margin-top': 0 })
        leaflet_text.css({ 'min-height': '1%' })
        var leaflet_height = parseInt(leaflet_text.css('height'))
        var title_bg_height = title_height + leaflet_height
        title.css({ height: title_bg_height })
        var bottom_margin = 30
        title.css({ 'margin-bottom': bottom_margin })
        var top_margin = $('.main-page-information')
          .children('.information-area')
          .children('.information-wrapper')
          .children('.info-top-margin')
        var top_margin_height = parseInt(top_margin.css('height')) + parseInt(top_margin.css('margin-bottom'))
        var row_separator = $('.main-page-information')
          .children('.information-area')
          .children('.information-wrapper')
          .children('.row-separator')
        var row_separator_height = parseInt(row_separator.css('height')) + parseInt(row_separator.css('margin-bottom'))
        var title_full_height = title_bg_height + bottom_margin
        var top_area_height = top_margin_height + row_separator_height + title_full_height

        var left_rectangle = $('.left-column').children('.info-rectangle')
        var right_rectangle = $('.right-column').children('.info-rectangle')
        left_rectangle.css({ height: 'auto' })
        right_rectangle.css({ height: 'auto' })
        if (left_rectangle.css('height') > right_rectangle.css('height')) {
          right_rectangle.css({ height: left_rectangle.css('height') })
        } else {
          left_rectangle.css({ height: right_rectangle.css('height') })
        }

        if (orientation === 'vertical') {
          $('.left-column').css({ width: '100%' })
          $('.left-column').css({ left: '0px' })
          left_rectangle.css({ width: '100%' })

          $('.right-column').css({ width: '100%' })
          right_rectangle.css({ width: '100%' })

          left_rectangle.css({ height: 'auto' })
          right_rectangle.css({ height: 'auto' })
          if (left_rectangle.css('height') > right_rectangle.css('height')) {
            right_rectangle.css({ height: left_rectangle.css('height') })
          } else {
            left_rectangle.css({ height: right_rectangle.css('height') })
          }

          var right_top = parseInt(left_rectangle.css('height')) + 20
          $('.right-column').css({ top: right_top })

          var wrapper = $('.main-page-information').children('.information-area').children('.information-wrapper')
          var info_area = $('.main-page-information').children('.information-area')
          var textarea = wrapper.children('.row-textarea')
          var new_textarea_height = right_top + parseInt(right_rectangle.css('height')) + 5
          textarea.css({ height: new_textarea_height })

          var wrapper_bottom_margin = 50
          var bottom_area_height = new_textarea_height + wrapper_bottom_margin
          var new_wrapper_height = top_area_height + bottom_area_height
          wrapper.css({ height: new_wrapper_height })
          var sheet_change_btn_height = parseInt($('.info-sheet-change-bg').css('height'))
          info_area.css({ height: new_wrapper_height + sheet_change_btn_height })
          $('.main-page-information').css({ height: new_wrapper_height + sheet_change_btn_height })
        } else {
          $('.left-column').css({ width: '50%' })
          left_rectangle.css({ width: '95%' })

          $('.right-column').css({ width: '50%' })
          right_rectangle.css({ width: '95%' })

          left_rectangle.css({ height: 'auto' })
          right_rectangle.css({ height: 'auto' })
          if (left_rectangle.css('height') > right_rectangle.css('height')) {
            right_rectangle.css({ height: left_rectangle.css('height') })
          } else {
            left_rectangle.css({ height: right_rectangle.css('height') })
          }

          $('.right-column').css({ top: '0px' })

          var wrapper = $('.main-page-information').children('.information-area').children('.information-wrapper')
          var info_area = $('.main-page-information').children('.information-area')
          var textarea = wrapper.children('.row-textarea')
          var new_textarea_height = parseInt(right_rectangle.css('height')) + 5
          textarea.css({ height: new_textarea_height })

          var wrapper_bottom_margin = 50
          var bottom_area_height = new_textarea_height + wrapper_bottom_margin
          var new_wrapper_height = top_area_height + bottom_area_height
          wrapper.css({ height: new_wrapper_height })
          var sheet_change_btn_height = parseInt($('.info-sheet-change-bg').css('height'))
          info_area.css({ height: new_wrapper_height + sheet_change_btn_height })
          $('.main-page-information').css({ height: new_wrapper_height + sheet_change_btn_height })

          var right_selected = $('#sheet-right').hasClass('sheet-change-selected')

          if (right_selected === true) {
            $('.left-column').css({ left: '25%' })
            $('.left-column').children('.info-rectangle').css({ width: '100%' })
          }
        }
      }
      var forced_pref_height_vert
      var forced_pref_height_hrz
      function mobilePreferences() {
        // Preferences
        var orientation = orientationCheck()
        var full_window = window.innerHeight
        if (orientation !== last_orientation) {
        }
        if (orientation === 'vertical') {
          if (forced_pref_height_vert === undefined || full_window > forced_pref_height_vert) {
            forced_pref_height_vert = full_window
          } else {
            full_window = forced_pref_height_vert
          }
        } else {
          if (forced_pref_height_hrz === undefined || full_window > forced_pref_height_hrz) {
            forced_pref_height_hrz = full_window
          } else {
            full_window = forced_pref_height_hrz
          }
        }

        var header_height = $('.main-page-navbar').height()
        var allowed_height = full_window - header_height
        $('.main-page-preferences').css({ height: allowed_height })
        $('.main-page-preferences').children('.information-wrapper').css({ height: allowed_height })

        if (orientation === 'horizontal') {
          $('.arrow-bg').css({ height: 30 })
          $('.arrow-bg').children().css({ height: 30 })
          $('.arrow-bg').children().css({ top: 0 })
          $('.arrow-bg').children().children().css({ 'font-size': '50pt' })
          $('.arrow-bg').children().children().css({ height: 'auto' })
          $('.arrow-bg').children().children().css({ 'margin-top': -15 })
        } else {
          $('.arrow-bg').css({ height: 80 })
          $('.arrow-bg').children().css({ height: 80 })
          $('.arrow-bg').children().css({ top: 0 })
          $('.arrow-bg').children().children().css({ 'font-size': '80pt' })
          $('.arrow-bg').children().children().css({ height: 'auto' })
          $('.arrow-bg').children().children().css({ 'margin-top': -15 })
        }

        var arrows = 0
        $('.arrow-bg').each(function () {
          arrows = arrows + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
        })
        var parent_paddings =
          parseInt($('.main-page-preferences').children('.information-wrapper').css('padding-top')) +
          parseInt($('.main-page-preferences').children('.information-wrapper').css('padding-bottom'))
        var preferences_height =
          allowed_height - arrows - parseInt($('.preferences-wrapper').css('margin-bottom')) - parent_paddings - 5
        $('.preferences-wrapper').css({ height: preferences_height })

        // Step 3. Figure overflow demand (is there preferences and is arrows needed)
        var demanded_height = parseInt($('.preferences-bg').css('height'))

        if (demanded_height > preferences_height) {
          // "Scrollable" bg
          $('.arrow').css({ display: 'inline-block' })
          controlArrows()
        } else {
          // Normal bg
          $('.arrow').css({ display: 'none' })
          $('.preferences-wrapper').css({ height: demanded_height })

          var height_count = 0
          $('.preferences-bg')
            .children('.row-textarea')
            .each(function () {
              height_count = height_count + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
            })

          height_count =
            height_count +
            2 * (parseInt($('.arrow-bg').css('height')) + parseInt($('.arrow-bg').css('margin-bottom'))) +
            12

          // Check if there is preferences at all
          if (height_count < 100) {
            $('.main-page-preferences').css({ display: 'none' })
          }
        }

        // Step 4. Title fixing and text fixing
        if (orientation === 'vertical') {
          $('.preferences-title').css({ width: '100%' })
          $('.preferences-title').css({ top: '0px' })
          $('.preferences-text-bg').css({ width: '100%' })
          $('.preferences-text-bg').css({ top: '20%' })
          $('.preferences-bg')
            .children('.row-textarea')
            .each(function () {
              var bg_top = parseInt($(this).children('.preferences-title').css('height'))
              $(this).children('.preferences-title').css({ 'padding-right': '0px' })
              $(this).children('.preferences-text-bg').css({ top: bg_top })
              var bg_height = 0
              $(this)
                .children('.preferences-text-bg')
                .children('.preferences-text')
                .each(function () {
                  bg_height = bg_height + parseInt($(this).css('height')) + parseInt($(this).css('margin-top'))
                })
              var new_padding = 15
              $(this).children('.preferences-text-bg').css({ 'padding-top': new_padding })
              bg_height = bg_height + new_padding
              $(this).children('.preferences-text-bg').css({ height: bg_height })
              var textarea_height = bg_top + bg_height + 20
              $(this).css({ height: textarea_height })
            })
        } else {
          $('.preferences-title').css({ width: '37%' })
          $('.preferences-title').css({ top: '0px' })
          $('.preferences-text-bg').css({ width: '53%' })
          $('.preferences-text-bg').css({ top: '0px' })
          $('.preferences-bg')
            .children('.row-textarea')
            .each(function () {
              $(this).children('.preferences-text-bg').css({ top: '0px' })
              var bg_height = 0
              $(this)
                .children('.preferences-text-bg')
                .children('.preferences-text')
                .each(function () {
                  bg_height = bg_height + parseInt($(this).css('height')) + parseInt($(this).css('margin-top'))
                })
              var new_padding = 30
              $(this).children('.preferences-text-bg').css({ 'padding-top': new_padding })
              bg_height = bg_height + new_padding
              $(this).children('.preferences-text-bg').css({ height: bg_height })
              var textarea_height = bg_height + 30
              $(this).css({ height: textarea_height })

              var bg_top = parseInt($(this).children('.preferences-title').css('height'))
              var new_top = ((textarea_height - bg_top) / 2) * 0.6
              $(this).children('.preferences-title').css({ top: new_top })
              $(this).children('.preferences-title').css({ 'padding-right': '5px' })
            })
        }
        last_orientation = orientation
      }
      function mobileFooter() {
        // Footer
        var orientation = orientationCheck()
        if (orientation === 'vertical') {
          $('.footer-column').addClass('mb-column')
        } else {
          $('.footer-column').removeClass('mb-column')
        }
      }
      function mobileSheetChange() {
        $('.student').css({ display: 'none' })
        $('.driving-school').css({ display: 'block' })
        $('.left-column').css({ left: '0px' })

        var orientation = orientationCheck()
        var title = $('.main-page-information')
          .children('.information-area')
          .children('.information-wrapper')
          .children('.row-title')
        var left_rectangle = $('.left-column').children('.info-rectangle')

        var left_selected = $('#sheet-left').hasClass('sheet-change-selected')
        var right_selected = $('#sheet-right').hasClass('sheet-change-selected')

        if (right_selected === true) {
          if (orientation === 'horizontal') {
            $('.left-column').css({ left: '25%' })
            $('.left-column').children('.info-rectangle').css({ width: '100%' })
          } else {
            $('.left-column').css({ left: '0px' })
            $('.left-column').children('.info-rectangle').css({ width: '95%' })
            if (parseInt($('.right-column').css('top')) > 5) {
              $('.left-column').children('.info-rectangle').css({ width: '100%' })
            }
          }

          title.children('.driving-school').css({ display: 'none' })
          title.children('.student').css({ display: 'block' })

          left_rectangle.children('.driving-school').css({ display: 'none' })
          left_rectangle.children('.student').css({ display: 'block' })

          $('.title-leaflet-text').children('.driving-school').css({ display: 'none' })
          $('.title-leaflet-text').children('.student').css({ display: 'block' })

          $('#leaflet-II').css({ visibility: 'hidden' })

          $('.right-column').css({ visibility: 'hidden' })
        } else {
          $('.left-column').css({ left: '0px' })
          $('.left-column').children('.info-rectangle').css({ width: '95%' })

          if (parseInt($('.right-column').css('top')) > 5) {
            $('.left-column').children('.info-rectangle').css({ width: '100%' })
          }

          title.children('.student').css({ display: 'none' })
          title.children('.driving-school').css({ display: 'block' })

          left_rectangle.children('.student').css({ display: 'none' })
          left_rectangle.children('.driving-school').css({ display: 'block' })

          $('.title-leaflet-text').children('.student').css({ display: 'none' })
          $('.title-leaflet-text').children('.driving-school').css({ display: 'block' })

          $('#leaflet-II').css({ visibility: 'visible' })

          $('.right-column').css({ visibility: 'visible' })
        }
      }

      var force_modal_width
      var force_modal_height
      function mobileModal() {
        var modal_frame_height = window.innerHeight * 0.9

        // Does the input fields have focus:
        var email_focus = $('#email-field').is(':focus')
        var password_focus = $('#password-field').is(':focus')
        if (email_focus === true || password_focus === true) {
          modal_frame_height = force_modal_height
        }
        force_modal_height = modal_frame_height

        var orientation = orientationCheck()
        if (force_modal_width === undefined) {
          if (orientation === 'vertical') {
            force_modal_width = window.innerWidth * 0.9
          }
        }

        if (orientation === 'horizontal') {
          if (force_modal_width !== undefined) {
            if (window.innerWidth * 0.9 >= force_modal_width - 5 && window.innerWidth * 0.9 <= force_modal_width + 5) {
              //Nothing in here
            } else {
              if (email_focus === true || password_focus === true) {
                $('#email-field').blur()
                $('#password-field').blur()
              }

              $('.login-fields-bg').css({ display: 'none' })
              var modal_land_width = window.innerWidth * 0.9
              var modal_land_height = window.innerHeight * 0.9
              $('.login-modal-frame').css({ width: modal_land_width })
              $('.login-modal-frame').css({ height: modal_land_height })

              $('.rotate-device-message').css({ display: 'inline-block' })
            }
          } else {
            if (email_focus === true || password_focus === true) {
              $('#email-field').blur()
              $('#password-field').blur()
            }

            $('.login-fields-bg').css({ display: 'none' })
            var modal_land_width = window.innerWidth * 0.9
            var modal_land_height = window.innerHeight * 0.9
            $('.login-modal-frame').css({ width: modal_land_width })
            $('.login-modal-frame').css({ height: modal_land_height })

            $('.rotate-device-message').css({ display: 'inline-block' })
          }
        } else {
          $('.rotate-device-message').css({ display: 'none' })
          $('.login-fields-bg').css({ display: 'inline-block' })

          var modal_port_width = window.innerWidth * 0.9
          $('.login-modal-frame').css({ width: modal_port_width })

          $('.login-modal-frame').css({ 'min-height': '200px' })
          $('.login-modal-frame').css({ height: modal_frame_height })

          $('.login-fields').removeClass('mb-login-fields-hrz')
          $('.login-fields').addClass('mb-login-fields-vrt')

          if ($('.main-page-bg').hasClass('ios') === true) {
            $('.mb-login-fields-vrt').css({ 'margin-top': '20%' })
          }

          $('.login-field').css({ width: '100%' })
          $('#login-email-field').css({ 'margin-right': '0px' })
          $('#login-password-field').css({ 'margin-left': '0px' })

          var login_field_text =
            parseInt($('.login-field:visible').css('width')) - parseInt($('.login-field-icon').css('width')) - 4
          if (isNaN(login_field_text)) login_field_text = $('.login-field-text').css('width')

          $('.login-field-text').css({ width: login_field_text })
          $('.login-modal-btn').css({ width: '100%' })
          $('.login-modal-buttons').css({ 'margin-top': '35px' })

          $('.login-error-text').css({ 'margin-top': '20px' })
          $('.login-problem').css({ 'margin-top': '10px' })
          $('.modal-line').css({ display: 'inline-block' })

          //This addition made after creating pc modal fixing
          $('#login-email-field').css({ display: 'block' })
          $('#login-password-field').css({ display: 'block' })
          var email_width = parseInt($('#login-email-field').css('width'))
          var password_width = parseInt($('#login-password-field').css('width'))
          var fields_width = parseInt($('.login-fields').css('width'))
          var email_offset_left = (fields_width - email_width) / 2
          var password_offset_left = (fields_width - password_width) / 2
          $('#login-email-field').css({ left: email_offset_left })
          $('#login-password-field').css({ left: password_offset_left })
        }
      }

      var ref_vrt_bg_size_old
      var ref_vrt_bg_size
      var ref_vrt_footer_top_old
      var ref_vrt_footer_top
      var ref_vrt_wrapper_size
      var ref_vrt_demanded_height
      var ref_vrt_wrapper_height

      var ref_hrz_bg_size_old
      var ref_hrz_bg_size
      var ref_hrz_footer_top_old
      var ref_hrz_footer_top
      var ref_hrz_wrapper_size
      var ref_hrz_demanded_height
      var ref_hrz_wrapper_height

      //preferencesFix(); //Erased 24.6.2020
      function preferencesFix() {
        var orientation = orientationCheck()
        var permit_to_do = false
        if (orientation === 'vertical' && mb_pref_vertical_done === false) {
          permit_to_do = true
        } else if (orientation === 'horizontal' && mb_pref_horizontal_done === false) {
          permit_to_do = true
        }

        if (permit_to_do === true) {
          // Fixes preferences layout to meet the insertion
          // Step 0. Mobile device figuring
          if (device_in_use !== 'pc') {
            mobilePreferencesInit()
          }
          // Step 1. Set preferences bg size to right and fix footer position
          var old_bg_height = parseInt($('.main-page-preferences').css('height'))
          var new_bg_size = old_bg_height - parseInt($('.main-page-navbar').css('min-height'))
          if (orientation === 'horizontal' && device_in_use !== 'pc') {
            var browser_bar_height = mb_full_window_height_vrt - mb_window_height_vrt
            new_bg_size =
              window.innerHeight * 2 - parseInt($('.main-page-navbar').css('min-height')) - browser_bar_height
          } else if (orientation === 'vertical' && device_in_use !== 'pc') {
            var browser_bar_height = mb_full_window_height_vrt - mb_window_height_vrt
            new_bg_size =
              window.innerHeight * 2 - parseInt($('.main-page-navbar').css('min-height')) - browser_bar_height
          }

          $('.main-page-preferences').css({ height: new_bg_size })
          var old_footer_top = parseInt($('.main-page-footer').css('top'))
          var new_footer_top = old_footer_top - (old_bg_height - new_bg_size)
          $('.main-page-footer').css({ top: new_footer_top })

          // Step 2. Set wrapper size to right
          var new_wrapper_size =
            parseInt($('#preferences-information-wrapper').css('height')) -
            2 * (parseInt($('.arrow-bg').css('height')) + parseInt($('.arrow-bg').css('margin-bottom'))) -
            10
          $('.preferences-wrapper').css({ height: new_wrapper_size })

          // Step 3. Figure overflow demand (is there preferences and is arrows needed)
          var demanded_height = parseInt($('.preferences-bg').css('height'))
          var wrapper_height = parseInt($('.preferences-wrapper').css('height'))

          if (demanded_height > wrapper_height) {
            // "Scrollable" bg
            $('.arrow').css({ display: 'inline-block' })
            $('.preferences-wrapper').scrollTop(0)
            controlArrows()
          } else {
            // Normal bg
            $('.arrow').css({ display: 'none' })
            $('.preferences-wrapper').css({ height: demanded_height })

            var height_count = 0
            $('.preferences-bg')
              .children('.row-textarea')
              .each(function () {
                height_count = height_count + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
              })

            height_count =
              height_count +
              2 * (parseInt($('.arrow-bg').css('height')) + parseInt($('.arrow-bg').css('margin-bottom'))) +
              12

            new_footer_top = old_footer_top - (old_bg_height - new_bg_size)
            var shrink_footer_top = new_footer_top - (new_bg_size - height_count)
            $('.main-page-preferences').css({ height: height_count })
            $('.main-page-footer').css({ top: shrink_footer_top })

            // Check if there is preferences at all
            if (height_count < 100) {
              $('.main-page-preferences').css({ display: 'none' })
              shrink_footer_top = old_footer_top - old_bg_height
              $('.main-page-footer').css({ top: shrink_footer_top })
            }
          }

          // Step 4. Title fixing and text fixing
          if ($('.preferences-title').hasClass('mb-preferences-title') === false) {
            $('.preferences-bg')
              .children('.row-textarea')
              .children('.preferences-title')
              .each(function () {
                var bg_height = parseInt($(this).parent().css('height'))
                var title_height = parseInt($(this).css('height'))
                var padding_top = parseInt($(this).css('padding-top'))
                var offset_top = (bg_height - title_height) / 2 - padding_top / 2
                $(this).css({ 'margin-top': offset_top })
              })
            $('.preferences-bg')
              .children('.row-textarea')
              .children('.preferences-text-bg')
              .children('.preferences-text')
              .each(function () {
                var bg_height = parseInt($(this).parent().css('height'))

                var text_height = parseInt($(this).css('height'))
                var padding_top = parseInt($(this).css('padding-top'))
                var offset_top = ((bg_height - text_height) / 2 - padding_top / 2) * 0.6
                $(this).parent().css({ 'padding-top': offset_top })
              })
          }

          if (orientation === 'vertical') {
            if (device_in_use !== 'pc') {
              ref_vrt_bg_size_old = old_bg_height
              ref_vrt_bg_size = new_bg_size
              ref_vrt_footer_top_old = old_footer_top
              ref_vrt_footer_top = new_footer_top
              ref_vrt_wrapper_size = new_wrapper_size
              ref_vrt_demanded_height = demanded_height
              ref_vrt_wrapper_height = wrapper_height
            }
          } else {
            if (device_in_use !== 'pc') {
              ref_hrz_bg_size_old = old_bg_height
              ref_hrz_bg_size = new_bg_size
              ref_hrz_footer_top_old = old_footer_top
              ref_hrz_footer_top = new_footer_top
              ref_hrz_wrapper_size = new_wrapper_size
              ref_hrz_demanded_height = demanded_height
              ref_hrz_wrapper_height = wrapper_height
            }
          }
        }
      }
      function mobilePreferencesInit() {
        // Step 1. Mobile screen adjust
        var orientation = orientationCheck()
        if (orientation === 'vertical' && mb_pref_vertical_done === false) {
          $('.preferences-bg')
            .children('.row-textarea')
            .each(function () {
              $(this).css({ 'min-height': '300px' })
              $('.preferences-title').css({ width: '100%' })
              $('.preferences-text-bg').css({ width: '100%' })
              var title_height = parseInt($(this).children('.preferences-title').css('height')) + 10
              var text_bg_height = parseInt($(this).children('.preferences-text-bg').css('height'))
              var new_bg_height = text_bg_height - title_height

              $(this).children('.preferences-text-bg').css({ height: new_bg_height })
              $(this).children('.preferences-text-bg').css({ top: title_height })

              $('.preferences-title').addClass('mb-preferences-title')
              $('.preferences-text').addClass('mb-preferences-text')
            })

          $('.arrow-bg').each(function () {
            $(this).css({ height: '120px' })
            $('.arrow').css({ 'font-size': '100pt' })
          })
          mb_pref_vertical_done = true
        } else if (device_in_use === 'phone' && mb_pref_horizontal_done === false) {
          // Horizontal when device is phone
          $('.preferences-bg')
            .children('.row-textarea')
            .each(function () {
              $(this).css({ 'min-height': '300px' })
              $('.preferences-title').css({ width: '100%' })
              $('.preferences-text-bg').css({ width: '100%' })
              var title_height = parseInt($(this).children('.preferences-title').css('height')) + 10
              var text_bg_height = parseInt($(this).children('.preferences-text-bg').css('height'))
              var new_bg_height = text_bg_height - title_height

              $(this).children('.preferences-text-bg').css({ height: new_bg_height })
              $(this).children('.preferences-text-bg').css({ top: title_height })

              $('.preferences-title').addClass('mb-preferences-title')
              $('.preferences-text').addClass('mb-preferences-text')
            })

          $('.arrow-bg').each(function () {
            $(this).css({ height: '120px' })
            $('.arrow').css({ 'font-size': '100pt' })
          })
          mb_pref_horizontal_done = true
        }
      }
      function preferencesFixTurn() {
        var orientation = orientationCheck()
        if (orientation === 'vertical') {
          // Step 1. Set preferences bg size to right and fix footer position
          $('.main-page-preferences').css({ height: ref_vrt_bg_size })
          $('.main-page-footer').css({ top: ref_vrt_footer_top })
          // Step 2. Set wrapper size to right
          $('.preferences-wrapper').css({ height: ref_vrt_wrapper_size })
          // Step 3. Figure overflow demand (is there preferences and is arrows needed)
          if (ref_vrt_demanded_height > ref_vrt_wrapper_height) {
            // "Scrollable" bg
            $('.arrow').css({ display: 'inline-block' })
            $('.preferences-wrapper').scrollTop(0)
            controlArrows()
          } else {
            // Normal bg
            $('.arrow').css({ display: 'none' })
            $('.preferences-wrapper').css({ height: ref_vrt_demanded_height })

            var height_count = 0
            $('.preferences-bg')
              .children('.row-textarea')
              .each(function () {
                height_count = height_count + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
              })

            height_count =
              height_count +
              2 * (parseInt($('.arrow-bg').css('height')) + parseInt($('.arrow-bg').css('margin-bottom'))) +
              12

            ref_vrt_footer_top = ref_vrt_footer_top_old - (ref_vrt_bg_size_old - ref_vrt_bg_size)
            var shrink_footer_top = ref_vrt_footer_top - (ref_vrt_bg_size - height_count)
            $('.main-page-preferences').css({ height: height_count })
            $('.main-page-footer').css({ top: shrink_footer_top })

            // Check if there is preferences at all
            if (height_count < 100) {
              $('.main-page-preferences').css({ display: 'none' })
              shrink_footer_top = ref_vrt_footer_top_old - ref_vrt_bg_size_old
              $('.main-page-footer').css({ top: shrink_footer_top })
            }
          }
        } else {
          // Step 1. Set preferences bg size to right and fix footer position
          $('.main-page-preferences').css({ height: ref_hrz_bg_size })
          $('.main-page-footer').css({ top: ref_hrz_footer_top })
          // Step 2. Set wrapper size to right
          $('.preferences-wrapper').css({ height: ref_hrz_wrapper_size })
          // Step 3. Figure overflow demand (is there preferences and is arrows needed)
          if (ref_hrz_demanded_height > ref_hrz_wrapper_height) {
            // "Scrollable" bg
            $('.arrow').css({ display: 'inline-block' })
            $('.preferences-wrapper').scrollTop(0)
            controlArrows()
          } else {
            // Normal bg
            $('.arrow').css({ display: 'none' })
            $('.preferences-wrapper').css({ height: ref_hrz_demanded_height })

            var height_count = 0
            $('.preferences-bg')
              .children('.row-textarea')
              .each(function () {
                height_count = height_count + parseInt($(this).css('height')) + parseInt($(this).css('margin-bottom'))
              })

            height_count =
              height_count +
              2 * (parseInt($('.arrow-bg').css('height')) + parseInt($('.arrow-bg').css('margin-bottom'))) +
              12

            ref_hrz_footer_top = ref_hrz_footer_top_old - (ref_hrz_bg_size_old - ref_hrz_bg_size)
            var shrink_footer_top = ref_hrz_footer_top - (ref_hrz_bg_size - height_count)
            $('.main-page-preferences').css({ height: height_count })
            $('.main-page-footer').css({ top: shrink_footer_top })

            // Check if there is preferences at all
            if (height_count < 100) {
              $('.main-page-preferences').css({ display: 'none' })
              shrink_footer_top = ref_hrz_footer_top_old - ref_hrz_bg_size_old
              $('.main-page-footer').css({ top: shrink_footer_top })
            }
          }
        }
      }

      function checkScroll() {
        var scroll = $('.preferences-wrapper').scrollTop()
        var max_scroll =
          parseInt($('.preferences-bg').css('height')) - parseInt($('.preferences-wrapper').css('height'))
        if (scroll > max_scroll - 19) {
          scroll = max_scroll
        }
        scroll = Math.round(scroll)
        return scroll
      }

      function scrollPreferences(direction) {
        var element_scroll = $('.preferences-wrapper').scrollTop()
        if (direction === 'down') {
          element_scroll = element_scroll + 20
          var max_scroll =
            parseInt($('.preferences-bg').css('height')) - parseInt($('.preferences-wrapper').css('height'))
          if (element_scroll > max_scroll) {
            element_scroll = max_scroll
          }
        } else {
          element_scroll = element_scroll - 20
          if (element_scroll < 0) {
            element_scroll = 0
          }
        }
        element_scroll = Math.round(element_scroll)
        $('.preferences-wrapper').scrollTop(element_scroll)
        controlArrows()
        original_scroll = element_scroll
      }

      function controlArrows() {
        var element_scroll = $('.preferences-wrapper').scrollTop()
        if (element_scroll < 10) {
          $('.arrow-up').addClass('arrow-no-select')
        } else {
          $('.arrow-up').removeClass('arrow-no-select')
        }

        var max_scroll =
          parseInt($('.preferences-bg').css('height')) - parseInt($('.preferences-wrapper').css('height'))
        if (element_scroll > max_scroll - 10) {
          $('.arrow-down').addClass('arrow-no-select')
        } else {
          $('.arrow-down').removeClass('arrow-no-select')
        }
      }

      var modal_open = false

      // Handle showing and hiding the set password dialog for new users. Show automatically on page load, and hide when closing the modal.
      if ($('#devise-modal').length > 0) {
        openCloseLoginModal()
        $('#login-form-area').hide()
        $('.login-modal-close-btn').on('click', function () {
          $('#devise-modal').hide()
        })
        $('.login-modal-bg').on('click', function (e) {
          if (e.target.classList.contains('login-modal-bg')) $('#devise-modal').hide()
        })

        var passwordInput = document.querySelector('#devise-modal input[type="password"]')
        if (passwordInput) {
          passwordInput.addEventListener('input', function (e) {
            var pwLengthEl = document.querySelector('.min-password-length')
            var length = e.target.value.length
            length < 8 ? pwLengthEl.classList.add('too-short') : pwLengthEl.classList.remove('too-short')
            length >= 8 ? pwLengthEl.classList.add('okay') : pwLengthEl.classList.remove('okay')
            length === 0 && pwLengthEl.classList.remove('too-short')
          })
        }
      }

      function openCloseLoginModal() {
        $('#password-forgot').hide()
        $('#login-form-area').show()
        $('.login-error-text').css({ visibility: 'hidden' })
        $('.login-field').removeClass('error-login-field')
        $('.login-icon-font').removeClass('error-login-icon')
        var orientation = orientationCheck()
        if (device_in_use !== 'pc') {
          $('.login-modal-frame').addClass('mb-login-modal-frame')
          $('.login-modal-close-btn').addClass('mb-login-modal-close-btn')
          $('.login-close-font').addClass('mb-login-close-font')
          if (orientation === 'vertical') {
            $('.login-fields').addClass('mb-login-fields-vrt')
            $('.login-fields').removeClass('mb-login-fields-hrz')
          } else {
            $('.login-fields').addClass('mb-login-fields-hrz')
            $('.login-fields').removeClass('mb-login-fields-vrt')
          }
        } else {
          $('.login-modal-frame').removeClass('mb-login-modal-frame')
          $('.login-modal-close-btn').removeClass('mb-login-modal-close-btn')
          $('.login-close-font').removeClass('mb-login-close-font')
          $('.login-fields').removeClass('mb-login-fields-hrz')
          $('.login-fields').removeClass('mb-login-fields-vrt')
        }

        $('#email-field').val('')
        $('#password-field').val('')
        var modal_position = -102
        if ($('.login-modal-bg').css('display') !== 'none') {
          $('body').css({ 'overflow-y': 'scroll' })
          $('.login-modal-frame').css({ top: '-102%' })
          $('.login-modal-bg').css({ display: 'none' })

          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            toggleFullScreen()

            $('.main-page-navbar').css({ display: 'inline-block' })
          }

          force_modal_width = undefined
          force_modal_height = undefined

          setTimeout(function () {
            modal_open = false
          }, 50)
        } else {
          if (
            $('.main-page-bg').hasClass('non-webkit') === true ||
            $('.main-page-bg').hasClass('ios') === true ||
            $('.main-page-bg').hasClass('chrome') === true
          ) {
            toggleFullScreen()

            $('.main-page-navbar').css({ display: 'none' })
          }
          modal_open = true

          $('body').css({ 'overflow-y': 'hidden' })
          focus_email = false
          focus_password = false
          $('.login-modal-bg').css({ display: 'inline-block' })
          var modal_down = setInterval(modalDown, 5)

          if (device_in_use !== 'pc') {
            mobileModal()
          } else {
            pcModal()
          }
        }
        function modalDown() {
          if ($('.main-page-bg').hasClass('ios') === true) {
            modal_position = modal_position + 2.6
          } else {
            modal_position = modal_position + 1.6
          }

          if (modal_position >= 4.8) {
            modal_position = 4.8
          }
          var new_position = modal_position + '%'
          $('.login-modal-frame').css({ top: new_position })
          if (modal_position >= 4.8) {
            clearInterval(modal_down)
            if ($('.login-modal-frame').hasClass('mb-login-modal-frame') === true) {
              var current_height = parseInt($('.mb-login-modal-frame').css('height'))
              $('.mb-login-modal-frame').css({ 'min-height': current_height })
            }
          }
        }
      }

      var focus_email = false
      var focus_password = false
      $('.login-field').on('mousedown touchstart', function (e) {
        //e.preventDefault();
        var field_id = e.target.id
        var splitted_id = field_id.split('-')
        var input_field_id = '#' + splitted_id[1] + '-' + splitted_id[2]
        var focus_is = $(input_field_id).is(':focus')

        setTimeout(function () {
          $(input_field_id).mousedown()
        }, 1)
      })
      $('.login-field-input').on('mousedown touchstart', function (e) {
        //e.preventDefault();
        var field_id = e.target.id
        var splitted_id = field_id.split('-')
        if (splitted_id[0] === 'email') {
          focus_password = false
          if (focus_email === true) {
            focus_email = false
            $('.login-fields-wrapper').focus()
          } else {
            focus_email = true
            $(this).focus()
          }
        } else {
          focus_email = false
          if (focus_password === true) {
            focus_password = false
            $('.login-fields-wrapper').focus()
          } else {
            focus_password = true
            $(this).focus()
          }
        }
      })
      $('.login-fields-wrapper').on('mousedown touchstart', function (e) {
        if (
          e.target.id !== 'email-field' &&
          e.target.id !== 'login-email-field' &&
          e.target.id !== 'icon-email-field' &&
          e.target.id !== 'zmdi-email-field'
        ) {
          focus_email = false
        }
        if (
          e.target.id !== 'password-field' &&
          e.target.id !== 'login-password-field' &&
          e.target.id !== 'icon-password-field' &&
          e.target.id !== 'zmdi-password-field'
        ) {
          focus_password = false
        }
      })

      $('.order-btn').on('mouseup touchend', function (e) {
        e.preventDefault()
        if (modal_open !== true) {
          window.open('https://www.vismasignforms.com/form/c128102a-0cf2-4be2-a6ae-4522fb18587c', '_blank')
        }
      })

      // New scroll events for mobile browsers
      $('.main-page-bg').scroll(function () {
        if (
          $('.main-page-bg').hasClass('non-webkit') === true ||
          $('.main-page-bg').hasClass('ios') === true ||
          $('.main-page-bg').hasClass('chrome') === true
        ) {
          var page_scroll = $('.main-page-bg').scrollTop()
          if (page_scroll > 30) {
            $('.mp-navbar').removeClass('greysmoke-navbar')
            $('.mp-navbar').addClass('whitesmoke-navbar')
            $('.navbar-logo').addClass('white-navbar-logo')

            if ($('.main-page-navbar').hasClass('mb-nabvar-bg-grey') === true) {
              mobileNavbar()
            }
            $('.mp-navbar').addClass('mb-nabvar-white')
            mobileNavbarNew()
          } else {
            $('.mp-navbar').removeClass('whitesmoke-navbar')
            $('.mp-navbar').addClass('greysmoke-navbar')
            $('.navbar-logo').removeClass('white-navbar-logo')

            if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
              mobileNavbar()
            }
            $('.mp-navbar').removeClass('mb-nabvar-white')
            mobileNavbarNew()
          }

          if ($('.main-page-navbar').hasClass('mb-nabvar-bg-white') === true) {
            //Otetaanko pois????
            figureFullWindowHeight()
          }

          // For iphone
          if ($('.main-page-bg').hasClass('ios') === true) {
            var forcing_for_iphone = parseInt($('.info-sheet-change-bg').offset().top)
            if (forcing_for_iphone < 100) {
              $('.mp-navbar').removeClass('greysmoke-navbar')
              $('.mp-navbar').addClass('whitesmoke-navbar')
              $('.navbar-logo').addClass('white-navbar-logo')

              if ($('.main-page-navbar').hasClass('mb-nabvar-bg-grey') === true) {
                mobileNavbar()
              }
              $('.mp-navbar').addClass('mb-nabvar-white')
              mobileNavbarNew()
            }
          }
        }
        changeToMobile()
        checkLayout()

        // For iphone
        if ($('.main-page-bg').hasClass('ios') === true) {
          var forcing_for_iphone_basic = parseInt($('.main-page-welcoming').offset().top)
          if (forcing_for_iphone_basic >= 0) {
            $('.mp-navbar').removeClass('force-white')
            $('.navbar-logo').removeClass('force-white-navbar-logo')
            $('.mp-navbar').removeClass('force-mb-nabvar-white')
          }
        }
      })

      function orientationCheck() {
        if (window.innerHeight > window.innerWidth) {
          return 'vertical'
        } else {
          return 'horizontal'
        }
      }
      function figureFullWindowHeight() {
        var orientation = orientationCheck()
        var window_height = window.innerHeight
        if (orientation === 'vertical') {
          // Figuring out vertical orientation full window size (browser navbar noticing)
          if (window_height > mb_window_height_vrt) {
            mb_full_window_height_vrt = window_height
          } else if (window_height < mb_window_height_vrt) {
            mb_window_height_vrt = window_height
          }
        } else {
          // Figuring out horizontal orientation full window size (browser navbar noticing)
          if (window_height > mb_window_height_hrz) {
            mb_full_window_height_hrz = window_height
          } else if (window_height < mb_window_height_hrz) {
            mb_window_height_hrz = window_height
          }
        }
      }

      var old_top = parseInt($('.main-page-preferences').css('top')) + 200
      $('.main-page-preferences').css({ top: old_top })
      checkLayout()
      function checkLayout() {
        // Last function to do. Making sure the sections are correct.
        var welcoming_height = parseInt($('.main-page-welcoming').css('height'))
        var welcoming_top = parseInt($('.main-page-welcoming').css('top'))
        var information_height = parseInt($('.main-page-information').css('height'))
        var information_top = parseInt($('.main-page-information').css('top'))

        var preferences_top = welcoming_height + information_height

        $('.main-page-preferences').css({ top: preferences_top })
        var footer_top = welcoming_height + information_height + parseInt($('.main-page-preferences').css('height'))
        $('.main-page-footer').css({ top: footer_top })
      }

      $('.login-field-input').on('focusin', function () {
        $('.login-error-text').css({ visibility: 'hidden' })
        $('.login-field').removeClass('error-login-field')
        $('.login-icon-font').removeClass('error-login-icon')
      })

      $(document).on('keypress', function (e) {
        if (parseInt(e.which) === 13 && $('.login-modal-bg').css('display') !== 'none') {
          $('#log-in').mousedown()
        }
      })

      function showErrorText() {
        $('.login-error-text').css({ visibility: 'visible' })
        $('.login-field').addClass('error-login-field')
        $('.login-icon-font').addClass('error-login-icon')
      }

      $('#log-in').on('mousedown touchstart', function (e) {
        e.preventDefault()
        var form = $('#login-form')
        var url = form.attr('action')

        $.ajax({
          type: 'POST',
          url: url,
          data: form.serialize(),
          dataType: 'json',
          success: function (data) {
            // Close modal when sign in is ok.
            if ($('.login-error-text').css('visibility') === 'hidden') {
              openCloseLoginModal()
            }
            window.location.href = '/after_sign_in'
          },
          error: function (jqXHR, textStatus, errorThrown) {
            $('.error-msg-login').hide()
            $('#error-default').show()
            if (jqXHR.responseJSON) {
              if (jqXHR.responseJSON.error) {
                if (jqXHR.responseJSON.error.includes('00001')) {
                  $('.error-msg-login').hide()
                  $('#error-inactive').show()
                } else if (jqXHR.responseJSON.error.includes('00002')) {
                  $('.error-msg-login').hide()
                  $('#error-not-activated').show()
                  $('#email-field-resend').val($('#email-field').val())
                  $('#activation-email-sent').hide()
                  $('#resend-activation').show()
                } else if (jqXHR.responseJSON.error.includes('00003')) {
                  $('.error-msg-login').hide()
                  $('#error-inactive-student').show()
                } else if (jqXHR.responseJSON?.locked_reason) {
                  $('.error-msg-login').hide()
                  $('#error-locked').text(`${jqXHR.responseJSON.error} \n ${jqXHR.responseJSON.locked_reason}`).show()
                }
              }
            }
            showErrorText()
          },
        })
      })

      $('#forgot-password').click(function () {
        $('#login-form-area').hide()
        $('#success-password').css({ visibility: 'hidden' })
        $('#password-forgot').show()
      })

      $('#back-login').click(function () {
        $('#password-forgot').hide()
        $('#login-form-area').show()
      })

      $('#resend-password').on('mousedown touchstart', function (e) {
        e.preventDefault()
        var form = $('#new_pw')
        var url = form.attr('action')

        $.ajax({
          type: 'POST',
          url: url,
          data: form.serialize(),
          dataType: 'json',
          success: function (data) {
            $('#success-password').css('visibility', 'visible')
          },
          error: function (jqXHR, textStatus, errorThrown) {
            $('#success-password').css('visibility', 'visible')
          },
        })
      })

      $('#resend-activation').on('mousedown touchstart', function (e) {
        e.preventDefault()
        $('#email-field-resend').val($('#email-field').val())
        var form = $('#resend-activation-form')
        var url = form.attr('action')

        $.ajax({
          type: 'POST',
          url: url,
          data: form.serialize(),
          dataType: 'json',
          success: function (data) {
            $('#resend-activation').hide()
            $('#activation-email-sent').show()
          },
        })
      })

      function toggleFullScreen() {
        if (
          !document.fullscreenElement && // alternative standard method
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement &&
          !document.msFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen()
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen()
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
          }
        }
      }
    })

    // Page loading information handling
    $(window).on('load', function () {
      $('.loading-message').css({ display: 'none' })
      clearInterval(loading)
    })
    setTimeout(function () {
      $('.loading-message').css({ display: 'none' })
      clearInterval(loading)
    }, 7000)

    var loading = setInterval(loadingTimer, 200)
    function loadingTimer() {
      if ($('.dot-I').css('visibility') === 'hidden') {
        $('.dot-I').css({ visibility: 'visible' })
      } else if ($('.dot-II').css('visibility') === 'hidden') {
        $('.dot-II').css({ visibility: 'visible' })
      } else if ($('.dot-III').css('visibility') === 'hidden') {
        $('.dot-III').css({ visibility: 'visible' })
      } else {
        $('.dot-I').css({ visibility: 'hidden' })
        $('.dot-II').css({ visibility: 'hidden' })
        $('.dot-III').css({ visibility: 'hidden' })
      }
    }
  }
}
