import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="teacher--products"
export default class extends Controller {
  connect() {
    $('.products-table').DataTable({
      tableTools: { sRowSelect: 'multi', sSelectedClass: 'row-selected' },
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      order: [1, 'asc'],
      responsive: true, // NOTE: This makes DataTables give the table a width of 0% if the table is hidden at first
      columns: [{ orderable: false }, null, null, null, { orderable: false }],
    })

    $('.archived-table').DataTable({
      tableTools: { sRowSelect: 'multi', sSelectedClass: 'row-selected' },
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      order: [0, 'asc'],
      columns: [null, null, null, null, { orderable: false }, { orderable: false }, { orderable: false }],
    })
  }
}
